.chat .chatToolbar {
	margin-bottom: 18px;
}

.chat .conversationNotesText {
	text-align: center;
	color: #1f58e7;
	margin-bottom: 14px !important;
	display: block;
	margin: 0 85px;
}

.chat .chatFooter {
	position: relative;
	bottom: 80px;
	margin-top: 70px;
}
.position-chat-to-menu-left {
	margin-left: 292px;
}
