.saveRow {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pt-card {
	height: 75px;
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: left;
	text-align: left;
	border-radius: 15px;
	background: white;
	color: black;
	padding-left: 7vw;
	margin: 10px;
}
.pt-row-card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: left;
	text-align: left;
}
.pt-saveTermsCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 20%;
	padding-right: 10px;
}
.buttonCardWIY {
	width: 100%;
	font-size: small;
}
.pt-selected {
	background: #59c5c7;
	color: white;
	justify-content: space-between;
}
.cards-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.results-label {
	background: #ff6760;
	width: 200px;
	height: 20px;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	color: white;
	font-weight: bold;
}
.result-card-title {
	font-size: 15px;
	line-height: 26px;
	font-weight: 700;
}
.result-card-title-coming-soon {
	font-size: 15px;
	line-height: 26px;
	font-weight: 700;
	color: red;
}
.result-card-sub-title {
	font-size: 16px;
	line-height: 19px;
	font-weight: 400;
	color: grey;
}
.result-card-npi {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
}
.pt-subtitle {
	text-align: center;
	width: 80%;
}
.space-title-np-rn {
	justify-content: center !important;
}
