
.settings-page ion-item, .settings-footer-page ion-item{
    --background:#fff;
    border-bottom: none;
    padding-top: 20px;
    padding-left:30px;
    padding-right: 30px;
    font-family: var(--ion-font-family);
    font-size: 14px;
    font-weight: 700;
}
ion-footer.settings-footer-page{
   padding-bottom: 30px;
   padding-left:16px;
   padding-right: 16px;
}
ion-footer.settings-footer-page::before{
    background:none;
}
.version{
    position: absolute;
    bottom: 15px;
    right: 30px;
}

