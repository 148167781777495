/*Original code */
@media screen and (max-width: 412px) {
	.custom-tabs ion-buttons {
		display: flex !important;
		flex-direction: row !important;
		justify-content: flex-start !important;
	}
	.sc-ion-buttons-md-s ion-button {
		--padding-top: 0;
		--padding-bottom: 0;
		--padding-start: 8px;
		--padding-end: 8px;
		--box-shadow: none;
		margin-left: 2px;
		margin-right: 2px;
		height: 32px;
		font-size: 0px !important;
		font-weight: 500;
	}
	.min-width-btns {
		min-width: 55px !important;
	}
}
@media screen and (min-width: 375px) {
	.custom-tabs ion-buttons {
		display: flex !important;
		flex-direction: row !important;
		justify-content: flex-start !important;
	}
	.sc-ion-buttons-md-s ion-button {
		--padding-top: 0;
		--padding-bottom: 0;
		--padding-start: 8px;
		--padding-end: 8px;
		--box-shadow: none;
		margin-left: 2px;
		margin-right: 2px;
		height: 32px;
		font-size: 0px !important;
		font-weight: 500;
	}
	.min-width-btns {
		min-width: 55px !important;
	}
}
@media screen and (min-width: 390px) {
	.custom-tabs ion-buttons {
		display: flex !important;
		flex-direction: row !important;
		justify-content: center !important;
	}
}
.custom-tabs {
	/* width: 90% !important; */
	/* max-width: 400px !important; */
	/* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
	border: none;
	max-height: 70px;
	height: 70px;
	/* margin: 0px 0px 20px 5% !important;
    height: 56px;
    border:none; */
	background: #fff;
	border-radius: 15px;
	-webkit-box-shadow: 1px -10px 10px -1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 1px -10px 10px -1px rgba(0, 0, 0, 0.05);
	box-shadow: 1px -10px 10px -1px rgba(0, 0, 0, 0.05);
}
.custom-tabs ion-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.custom-tabs ion-buttons ion-button {
	height: 70px;
}
.custom-tabs ion-buttons ion-button button {
	height: 70px;
}
.custom-tabs ion-buttons ion-button div {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	font-size: 8px;
	font-family: var(--ion-font-family);
	text-transform: uppercase;
	font-weight: 400;
	color: #a0a4a8;
}
.custom-tabs ion-buttons ion-button div.selected-tab {
	color: #336cfb;
}
.custom-tabs ion-buttons ion-button div ion-icon {
	width: 20px;
	height: 20px;
	margin-bottom: 5px;
}

ion-fab-button.fab-main-tab {
	width: 50px;
	height: 50px;
	top: -30px;
	position: relative;
	--background: #f6f8fb;
	--box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.05), 0 1px 18px 0 rgba(0, 0, 0, 0.05);
}

.custom-tabs ion-buttons ion-button ion-label {
	font-size: 8px;
	text-transform: uppercase;
	padding-top: 11px;
}
.menufooter {
	bottom: -webkit-calc(-100% + 80px);
	bottom: calc(-100% + 80px);
}

.custom-tabs ion-tab-button.custom-tabs__settings {
	border-top-left-radius: 20px;
}

.custom-tabs ion-tab-button.custom-tabs__actions {
	border-top-right-radius: 20px;
}
.custom-tabs ion-tab-button::after {
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: '';
	display: block;
	height: 7px;
	position: absolute;
	transition: width 0.4s ease 0s, background-color 0.4s ease;
	width: 0;
	margin-bottom: 12px;
	border-radius: 5px;
}
.custom-tabs ion-tab-button i {
	content: '';
	height: 22px;
	width: 22px;
}
.custom-tabs ion-tab-button i.tab-icon-settings {
	background: url(../../img/main-menu/icon-tab-settings.svg) no-repeat;
}
.custom-tabs ion-tab-button.tab-selected i.tab-icon-settings {
	background: url(../../img/main-menu/icon-tab-settings-active.svg) no-repeat;
}
.custom-tabs ion-tab-button i.tab-icon-history {
	background: url(../../img/main-menu/icon-tab-history.svg) no-repeat;
}
.custom-tabs ion-tab-button.tab-selected i.tab-icon-history {
	background: url(../../img/main-menu/icon-tab-history-active.svg) no-repeat;
}
.custom-tabs ion-tab-button i.tab-icon-ambassador {
	background: url(../../img/main-menu/icon-tab-ambassador.svg) no-repeat;
}
.custom-tabs ion-tab-button.tab-selected i.tab-icon-ambassador {
	background: url(../../img/main-menu/icon-tab-ambassador-active.svg) no-repeat;
}
.custom-tabs ion-tab-button i.tab-icon-my-wallet {
	background: url(../../img/main-menu/icon-tab-my-wallet.svg) no-repeat;
}
.custom-tabs ion-tab-button.tab-selected i.tab-icon-my-wallet {
	background: url(../../img/main-menu/icon-tab-my-wallet-active.svg) no-repeat;
}
.custom-tabs ion-tab-button i.tab-icon-actions {
	background: url(../../img/main-menu/icon-tab-actions.svg) no-repeat;
}
.custom-tabs ion-tab-button.tab-selected i.tab-icon-actions {
	background: url(../../img/main-menu/icon-tab-actions-active.svg) no-repeat;
}

.spinner {
	color: #2da585 !important;
	background: transparent !important;
}
.loading-translucent.sc-ion-loading-ios-h .loading-wrapper.sc-ion-loading-ios {
	background: transparent !important;
	backdrop-filter: none;
	-webkit-background: transparent !important;
	-moz-background: transparent !important;
	-webkit-backdrop-filter: transparent !important;
	-moz-backdrop-filter: transparent !important;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}
.loading-translucent.sc-ion-loading-md-h .loading-wrapper.sc-ion-loading-md {
	background: transparent !important;
	backdrop-filter: none;
	-webkit-background: transparent !important;
	-moz-background: transparent !important;
	-webkit-backdrop-filter: transparent !important;
	-moz-backdrop-filter: transparent !important;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

.tabsPage {
	align-items: center;
}

.custom-tabs .notification-conversation-badge {
	position: fixed;
	z-index: 10000;
	font-size: 8px;
	border-radius: 100%;
	left: 40px;
	width: 10px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.min-width-btns {
	min-width: 60px;
}
.custom-tabs .pending-tasks-badge {
	position: fixed;
	z-index: 10000;
	font-size: 8px;
	border-radius: 100%;
	left: 34px;
	width: 15px;
	height: 15px;
	display: flex;
	top: 10px;
	align-items: center;
	justify-content: center;
}
