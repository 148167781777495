.passportContent {
	background-image: linear-gradient(158.83deg, #6360ff 1.3%, #2402f2 100%);
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}

.front,
.passBack {
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 0;
}

.passBack {
	background-image: linear-gradient(158.83deg, #6360ff 1.3%, #2402f2 100%);
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.noRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}
.header-passport {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 30px;
}
.passport-title {
	font-size: 20px;
	font-weight: bold;
	color: #ffff;
	align-items: center;
	padding-left: 10px;
}
.card-face-passport {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	backface-visibility: hidden;
}
.card-passport-number {
	color: #fff;
	font-size: 14px;
}
.card-passport-number .span {
	font-size: 20px;
	font-weight: 700;
	color: white;
}
.card-passport-number .copy {
	font-size: 18px;
	padding-left: 8px;
}
.edit-passport-image {
	width: 40px;
	height: 40px;
	position: absolute;
	justify-content: center;
	align-items: center;
	bottom: 0px;
	right: -17px;
	background: #336cfb;
	border-radius: 50%;
}
.container-edit-passport {
	position: relative;
	justify-content: flex-end;
}
