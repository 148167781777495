.saveRowProfile {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.upload-media-profile-avatar {
	width: 100%;
	border: 5px solid #a0a4a8;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	margin: 5px;
	overflow: hidden;
}
.upload-media-profile-avatar .upload-area {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.upload-media-profile-avatar .upload-area img {
	margin: 10px;
	max-width: 100%;
	max-height: 100%;
}
.upload-media-profile-avatar .upload-area ion-icon {
	margin: 10px;
	max-width: 100%;
	max-height: 100%;
	font-size: 90px;
	color: #a0a4a8;
}
.profile-border-input::part(native) {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
}
