.card-sam {
	transform-style: preserve-3d;
	transform-origin: center;
	transition: transform 1s;
}
.card-sam-actions {
	background-color: white;
	background-position: bottom;
	background-repeat: repeat-x;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-image: url('../../../img/landing-hero 1.png');
}
.header-sam-actions {
	border-radius: 20px 20px 70% 40%;
	display: flex;
	flex-direction: row;
	width: 100%;
	background: #e3f3ff;
	height: 56px;
	align-items: baseline;
	padding-left: 18px;
	padding-top: 10px;
	justify-content: space-between;
}
.wrapper-sam {
	min-height: calc(100% - 150px);
	position: relative;
}
.icon-sam-widht {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
.sam-title {
	font-size: 20px;
	font-weight: bold;
	color: #25282b;
	align-items: center;
}
.all-clear-top {
	margin-top: 20px;
	margin-bottom: 20px;
}
.text-sam {
	font-size: 14px;
	text-align: center;
	align-items: baseline;
	color: #25282b;
	font-weight: 400;
	padding-right: 10px;
}
.what-is-this-title-sam {
	font-size: 14px;
}
.footer-sam-container {
	color: white;
	width: 100%;
	height: 110px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	justify-content: center;
}
.container-sam {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	padding: 12px;
}
.flip-sam {
	color: black;
	font-weight: 500;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 5px;
	font-size: 14px;
}
.sam-back {
	background-image: linear-gradient(158.83deg, #ffffff 1.3%, #ffffff 100%);
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.row-accordeon-sam-card {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.title-content-text-sam {
	font-family: var(--ion-font-family3);
	padding-left: 10px;
	color: #e74f48;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}
.content-text-sam {
	font-family: var(--ion-font-family3);
	padding-left: 10px;
	font-size: 12px;
	font-weight: 700;
	color: #25282b;
	line-height: 16px;
	padding-bottom: 10px;
}
.content-sam-actions-front {
	overflow-y: auto;
}
.accordion-wrapper-sam {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.m-top-sam {
	margin-top: 10px;
}
.padding-content-sam {
	padding-left: 15px;
}
.footer-btn-container {
	color: black;
	width: 100%;
	position: absolute;
	bottom: 20px;
	left: 0;
	display: flex;
	justify-content: center;
}
.container-btn-sam {
	display: flex;
	justify-content: space-evenly;
	padding: 10px;
}
.row-btn-sam {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	margin-right: 10px;
	font-weight: 500;
}
.icon-sam {
	width: 50px;
	height: 50px;
	background: #336cfb;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.icon-btn-size {
	width: 15px;
	height: 17px;
}
.wrapper-zoom-sam {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.wrapper-flip-sam {
	padding-bottom: 60px;
	min-height: calc(100% - 60px);
	position: relative;
}
.icon-arrow-sam {
	width: 15px;
	height: 17px;
	padding-top: 15px;
}
.icon-about-sam-exclusion {
	width: 24px;
	height: 24px;
	position: absolute;
	right: 25px;
}
.description-black-about-sam {
	color: black;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	padding: 15px;
}
.content-text-sam {
	font-family: var(--ion-font-family3);
	padding-left: 10px;
	font-size: 14px;
	font-weight: 400;
	color: #25282b;
	line-height: 16px;
	padding-bottom: 10px;
}
.title-content-text-sam {
	font-family: var(--ion-font-family3);
	padding-left: 10px;
	color: #e74f48;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
}
.order-date-title-sam {
	color: #25282b;
	font-weight: 300;
	font-size: 14px;
	line-height: 12px;
	padding-left: 10px;
}
.text-sam-title-exclusion {
	font-size: 20px;
	align-items: baseline;
	color: black;
	font-weight: bold;
	padding-left: 10px;
}
.bubble-sam {
	flex-grow: 0;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: inline-flex;
	background: #ff0000;
	padding: 0;
	margin-left: 15px;
}

.bubble-sam span {
	font-family: var(--ion-font-family3);
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
.all-clear-title-sam {
	font-family: var(--ion-font-family3);
	font-weight: 900;
	font-size: 20px;
	color: #037a32;
	padding-left: 10px;
}
.on-off-label-sam {
	color: #25282b;
	font-weight: 400;
	font-size: 14px !important;
}
.row-data-sam-exc {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 30px;
}
.padding-content-sam {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
}
.previous-matches-sam {
	flex-direction: row !important;
}
.previous-matches-text-sam {
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: #938eff;
	padding-left: 10px;
}
.previous-matches-description-sam {
	font-size: 14px;
	color: black;
}
.flex-column-sam {
	display: flex;
	flex-direction: column;
}
.exclusion-posible-sam {
	font-weight: 700;
	font-size: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #6d55ff;
	justify-content: center;
}
.exclusion-found-sam {
	font-weight: 700;
	font-size: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #f05851;
	justify-content: center;
}
.width-samexc {
	width: 100%;
}
.content-center-sam-exc {
	justify-content: center;
}
.margin-twenty-sam {
	margin: 20px;
}
.hide-content-no-zoom-sam {
	overflow: hidden;
	max-height: 455px;
}
