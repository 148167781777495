.card__leie-actions-body {
	color: #fff;
}
.card__leie-actions-body {
	color: #fff;
	display: flex;
	flex-direction: column;
}
.card__leie-actions-body ion-text.span {
	padding: 5px 0;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	font-size: 13px;
}
.card__leie-actions-body ion-text.detail {
	font-weight: 700;
}
.card__leie-actions-body ion-text.span .datetime {
	padding: 0;
	padding-left: 3px !important;
}
.card__leie-actions-footer {
	padding-top: 10px;
	color: #fff;
	display: flex;
	justify-content: center;
	width: 100%;
}
.card__leie-actions {
	background: linear-gradient(158.83deg, #2f4b79 1.3%, #091936 100%);
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.article-header__leie-actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 25px;
	border-bottom: 2px solid #75b4f1;
	background: #091936;
	border-radius: 20px 20px 0px 0px;
	padding-top: 10px;
}
.dlNoRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}
.all-clear-title-leie {
	font-family: var(--ion-font-family3);
	font-weight: 900;
	font-size: 20px;
	color: #67d392;
	padding-left: 10px;
}
.what-is-this__title-leie {
	font-family: var(--ion-font-family3);
	font-weight: bold;
	font-size: 14px;
	line-height: 14.4px;
	color: rgba(255, 255, 255, 0.6);
}
.what-is-this__content-leie {
	font-family: var(--ion-font-family3);
	font-weight: bold;
	font-size: 12px;
	color: rgba(255, 255, 255, 0.6);
	line-height: 14.4px;
}
.padding-content {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
}
.last-checked-leie-top {
	margin-top: 32px;
}
.last-checked-leie ion-text {
	color: #ffffff;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}
.last-checked-leie-act ion-text {
	color: #ffffff;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
	margin-right: 10px;
}
.last-checked-leie ion-text.span {
	font-weight: 400;
	padding-left: 5px;
	color: #ffffff;
}
.on-off-label-leie {
	color: #ffffff !important;
	font-weight: 400;
	font-size: 14px;
	margin-left: 10px;
}
.bubble-leie {
	flex-grow: 0;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: inline-flex;
	background: #2e2e2e;
	padding: 0;
	margin-left: 15px;
}
.bubble-leie span {
	font-family: var(--ion-font-family3);
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
.link-leie {
	font-family: var(--ion-font-family3);
	font-weight: bold;
	color: #ffffff;
	font-size: 14px;
	line-height: 14.4px;
	text-decoration: none;
}
.category-title-leie {
	font-size: 20px !important;
	text-align: left;
	font-size: var(--ion-font-family2);
	font-weight: 700;
	color: #75b4f1;
	margin-bottom: 10px;
	margin-left: 18px;
}
.all-clear-oig-row {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}
.row-oig {
	display: flex;
	position: fixed;
}
.img-oig {
	position: absolute;
	top: 25px;
	display: flex;
	right: 10px;
	width: 150px;
}
.content-leie-actions-front {
	overflow-y: auto;
	height: 370px;
}
.title-content-text-leie {
	font-family: var(--ion-font-family3);
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}
.content-text-leie {
	font-family: var(--ion-font-family3);
	font-size: 14px;
	font-weight: 300;
	color: #ffffff;
	line-height: 14.4px;
	padding-bottom: 10px;
}
.wrapper-zoom-leie {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.wrapper-leie {
	padding-bottom: 60px;
	min-height: calc(100% - 60px);
	position: relative;
}
.footer-leie-container {
	color: white;
	width: 100%;
	height: 110px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	justify-content: center;
}
.footer-btn-container-leie {
	color: black;
	width: 100%;
	position: absolute;
	bottom: 20px;
	left: 0;
	display: flex;
	justify-content: center;
}
.leie-close-card {
	width: 100%;
	justify-content: center;
	padding: 10px;
	color: #75b4f1;
}
.icon-about-leie-oig {
	width: 24px;
	height: 24px;
}
.monitoring-row-leie {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* margin-top: 30px !important; */
	width: 100%;
	display: flex;
}
.previous-matches {
	flex-direction: row !important;
}
.previous-matches-text {
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: #45b5d8;
	padding-left: 10px;
}
.previous-matches-description {
	font-size: 14px;
	color: #ffffff;
}
.flex-column-leie {
	display: flex;
	flex-direction: column;
}
.exclusion-posible {
	font-weight: 700;
	font-size: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #fac032;
	justify-content: center;
}
.exclusion-found {
	font-weight: 700;
	font-size: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #f05851;
	justify-content: center;
}
.hide-content-no-zoom {
	overflow: hidden;
	max-height: 455px;
}
