.card__abms-body {
	padding-top: 30px;
	color: #fff;
}
.card__abms-body {
	padding: 15px;
	color: #fff;
	display: flex;
	flex-direction: column;
}
.card__abms-body ion-text.span {
	padding: 5px 0;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	font-size: 13px;
}
.card__abms-body ion-text.detail {
	font-weight: 700;
}
.card__abms-body ion-text.span .datetime {
	padding: 0;
	padding-left: 3px !important;
}
.card__abms-footer {
	padding-top: 10px;
	color: #fff;
	display: flex;
	justify-content: center;
	width: 100%;
}
.card__abms {
	background-image: linear-gradient(158.83deg, #ffeef3 1.3%, #ffa8c7 100%);
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}
.card__abms__no__bg__img {
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}
.container-abms {
	background-image: url('../../../img/icons/ABMS_shield.svg');
	background-repeat: no-repeat;
	background-position: right 20px top 65px;
}
.article-header__dea-license {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
	font-weight: 700;
}

.abmsFront,
.abmsBack {
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 0;
}

.abmsBack {
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.abmsNoRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}

.abms-content-card ion-text.span {
	display: block;
	font-size: 13px;
	color: #25282b;
	font-weight: 500;
}

.flex-col {
	display: flex;
	flex-direction: column;
}
.status-abms {
	text-transform: uppercase;
}
.abms-datetime {
	padding: 0 !important;
	display: block;
	font-size: 14px;
	line-height: 16px;
	color: #25282b;
}
.close-card {
	width: 100%;
	justify-content: center;
	padding: 10px;
}

.card {
	transform-style: preserve-3d;
	transform-origin: center;
	transition: transform 1s;
}

.card__face {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 455px;
	backface-visibility: hidden;
}

.default-abms ion-text.title {
	text-transform: capitalize;
	font-family: var(--ion-font-family3);
	font-size: 18px;
	color: #000;
	font-weight: 900;
	padding-top: 5px;
}

.bubble-abms {
	flex-grow: 0;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: inline-flex;
	background: #ff6760;
	padding: 0;
	margin-left: 15px;
}

.bubble-abms span {
	font-family: var(--ion-font-family3);
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
.icon-about-abms-actions {
	width: 24px;
	height: 24px;
}
.description-black-abms {
	color: black;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	padding-left: 15px;
}
.flex-column-abms {
	display: flex;
	flex-direction: column;
}
.content-abms-actions-front {
	overflow-y: auto;
	height: 370px;
	width: 100%;
}
.title-content-text-abms-data {
	font-family: var(--ion-font-family3);
	color: #00744a;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}
.content-text-abms-data {
	font-family: var(--ion-font-family3);
	font-size: 14px;
	font-weight: 400;
	color: #000000;
	line-height: 12px;
}
.description-abms {
	color: #3c3c3c;
	font-weight: 400;
	font-size: 14px;
	line-height: 12px;
}
.title-abms {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #000000;
}
.logo-abms-shield {
	position: relative;
	display: flex;
	justify-content: right;
	width: 100%;
}
.wrapper-zoom-abms {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.article-header-abms {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
}
.all-clear-icon-abms {
	width: 100px;
	height: 100px;
}
.row-all-clear-abms {
	display: flex;
	justify-content: center;
}
.all-clear-subtitle-abms {
	font-family: var(--ion-font-family3);
	font-weight: 400;
	font-size: 17px;
	color: #000000;
	line-height: 20.4px;
	padding-top: 8px;
}
.logo-abms-shield-no-data {
	position: absolute;
	right: 20px;
	bottom: 25px;
	z-index: -1;
}

.abms-message {
	position: absolute;
	bottom: 50%;
	font-size: 20px;
	font-weight: 500;
	margin: 10px;
}
.position-content-abms {
	position: absolute;
	top: 52px;
	z-index: 1000;
}
.full-width-abms {
	width: 100%;
}
.flex-between-abms {
	display: flex;
	justify-content: space-between;
}
.abms-margin-top-16 {
	margin-top: 16px;
}
