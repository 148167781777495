.logo-img{
    width: 102px;
    height: 27px;
    margin: 0 auto;
}
.header__privacy-sec{
    margin-top: 50px;
}
.title__privacy-sec{
    font-size: 20px;
    font-weight: 600;
    font-family: var(--ion-font-family2);
}
.text__privacy-sec{
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
    overflow:scroll;
    max-height: 50vh;
    
}
.text__privacy-sec h1{
    font-size: 18px;
    line-height: 24px;
}
.text__privacy-sec h2{
    font-size: 17px;
    line-height: 24px;
}
ion-text.label__privacy-sec{
    font-size: 18px;
    line-height: 24px;
}
ion-checkbox.checkbox__privacy-sec{
    margin-right: 12px;
}
.table{
    width: 100%;
}
.rowTable{
    border: solid 1px black;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.subjectRow{
    display: flex;
    justify-content: center;
    width: 40%;
    border-right: solid 1px black;
}
.detailRow{
    display: flex;
    justify-content: center;
    width: 60%;
}