.stepper {
	font-size: 10px;
	line-height: 12px;
	font-weight: 500;
	position: absolute;
	counter-reset: step 0;
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-flow: row nowrap;
	gap: 0;
	justify-content: space-between;
	align-items: flex-start;
	bottom: 50px;
}
.stepper > .step {
	position: relative;
	counter-increment: step;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
}
.stepper > .step1 {
	position: relative;
	counter-increment: step;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
}
.stepper > .step2 {
	position: relative;
	counter-increment: step;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}
.stepper > .step3 {
	position: relative;
	counter-increment: step;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	align-items: flex-end;
}
.stepper > .step4 {
	position: relative;
	counter-increment: step;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	align-items: flex-end;
}
.stepper > .step::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 5px;
	background: #8ef8fa;
	left: 0;
	top: 0.3rem;
}
.stepper > .step > .step-label {
	position: relative;
	width: 15px;
	height: 15px;
	line-height: 15px;
	background: #8ef8fa;
	color: #8ef8fa;
	text-align: center;
	border-radius: 50%;
}
.stepper > .step > .step-label::before {
	content: counter(step);
}
.stepper > .step.active::before {
	background: linear-gradient(to right, #00a585, #8ef8fa);
}
.stepper > .step.active > .step-label {
	background: #00a585;
	color: #00a585;
	font-weight: bold;
}
.stepper > .step.completed::before {
	background: #00a585;
}
.stepper > .step.completed > .step-label {
	background: #00a585;
	color: #00a585;
}
.stepper > .step > .step-description {
	font-size: 10px;
	line-height: 12px;
	font-weight: 500;
	color: #00a585;
	margin-top: 0.5rem;
	overflow-wrap: break-word;
	hyphens: auto;
	text-align: center;
}
.stepper > .step.active > .step-description,
.stepper > .step.completed > .step-description {
	color: #00a585;
}
.stepper > .step.active > .step-description {
	font-weight: bold;
}
