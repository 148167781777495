.input-radio-style {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
}
.flex-div {
	display: flex;
}
.startDateStyle {
	flex: 100%;
	margin-right: 2px;
}
.endDateStyle {
	flex: 100%;
	margin-left: 2px;
}
.input-radius-tasks {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
	padding: 5px;
}
.flex-order-column {
	display: flex;
	flex-direction: column;
}
.input-font-size-tasks {
	font-size: 20px;
}
.dateInput .date-time-style {
	border: none;
	width: 100%;
	background: none;
}
div div .test input {
	border: none;
	width: 100%;
	background: none;
}
.input-date-border-style {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
	padding: 15px;
}
.test {
	width: 100%;
}
.margin-name-history {
	margin-right: 5px;
}
@media only screen and (max-width: 580px) {
	.flex-div {
		display: flex;
		flex-direction: column;
	}
}
.error-date {
	font-size: 16px;
	color: red;
	font-weight: 500;
}
.position-header-caqh {
	margin-top: 70px;
}
.margin-caqh {
	margin: 10px;
}
.text-instructions {
	display: flex;
	flex-direction: column;
}
.container-title-task {
	display: flex;
	justify-content: center;
	font-size: 24px;
}
