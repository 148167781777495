.save-row-create-account {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.or {
	width: 100%;
	justify-content: center;
	text-align: center;
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 700;
	font-size: large;
}
.user-title-onboarding {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px;
}
.user-title-onboarding-name {
	font-size: 20px;
	line-height: 26px;
	font-weight: 500;
	padding-bottom: 5px;
}
.user-title-onboarding-npi {
	font-size: 16px;
	line-height: 18px;
}
