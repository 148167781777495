.upload-media-container {
	width: 100%;
	border: 2px dashed #dbdde0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	width: 250px;
	height: 200px;
	margin: 5px;
}
.upload-media-container .upload-area {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.upload-media-container .upload-area img {
	margin: 10px;
	width: 54px;
}

.upload-media-container .upload-area ion-label {
	font-size: 13px;
	color: #52575c;
}

.upload-media-container .buttons-area {
	padding: 20px;
}
.upload__main-title {
	font-weight: 700;
	padding-top: 15px;
	font-size: 20px;
}
.upload-content-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.upload-content-info h4 {
	font-size: 14px;
	font-weight: 700;
	color: #52575c;
	margin-bottom: 5px;
}
.upload-content-info ion-label {
	font-size: 13px;
	font-weight: 400;
	color: #a0a4a8;
}
.upload-content-info p {
	text-align: center;
	font-size: 13px;
	line-height: 16px;
	color: #52575c;
}
.padding-license-page {
	--padding-start: 50px !important;
	--padding-end: 50px !important;
}
.result-image-license {
	display: flex;
	flex-direction: column-reverse;
}
.result-image-license img {
	border-radius: 10px;
}
.details-license-info {
	font-weight: 400;
	font-size: 15px;
	padding-top: 30px;
}
.details-license-info span {
	display: block;
	padding: 5px 0;
}
.details-license-info span.details-info__title {
	color: #b1b1b1;
	font-weight: 500;
	font-size: 16px;
}
.title-reminder img {
	display: inline-block;
	width: 15px;
	height: 15px;
	margin-right: 5px;
}
.title-reminder p {
	display: inline-block;
	color: #777;
	font-size: 14px;
}
.label-message-license {
	margin: 20px 0;
	font-size: 18px;
	color: #52575c;
	text-align: center;
}
.row-pics {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}
.upload-main-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
}
.uploadFileRow {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.upload-content-info-passport {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 20px;
}
.upload-note-data-passport {
	font-size: 13px;
	line-height: 16px;
	color: #52575c;
}
.upload-note-passport {
	font-weight: 700;
	font-size: 20px;
}
.upload-file-row-passport {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}
.btn-save-passport {
	width: 80%;
}
