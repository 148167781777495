.card__medical-license-header .div {
	display: flex;
	flex-direction: column;
	padding-left: 10px;
}

.card__medical-license-header .div .title-name-license {
	font-size: 20px;
	font-weight: 700;
	font-family: var(--ion-font-family2);
	line-height: 20px;
}
.card__medical-license-header .div .subtitle-name-license {
	font-size: 14px;
	font-weight: 400;
}

.card__medical-license-body {
	font-size: 13px;
	font-weight: 400;
}
.card__medical-license-npi {
	padding-top: 30px;
	padding-bottom: 20px;
	line-height: 30px;
}
.card__medical-license-npi .span {
	font-size: 20px;
	font-weight: 500;
}
.card__medical-license-body .numbers-ids {
	font-size: 13px;
}
.card__medical-license-body .numbers-ids .span {
	display: block;
}
.card__medical-license-body .numbers-ids .ids-numbers {
	font-weight: 500;
}

.card__medical-license-footer .span {
	display: block;
}
.card__medical-license-footer .title-license-location {
	font-family: var(--ion-font-family2);
	font-weight: 700;
	font-size: 20px;
}
.card__medical-license-footer .title-license-number {
	color: var(--ion-color-primary);
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
}
.card__medical-license-footer .title-license-expiration {
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.noZoom {
	overflow: hidden;
	max-height: 300px;
}
.zoom {
	overflow: scroll;
}
.footer {
	width: 100%;
	height: 30px;
	background: linear-gradient(rgb(182 218 245 / 73%), rgb(255 255 255 / 70%));
	position: absolute;
	bottom: 0;
	left: 0;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.card__medical-table {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	margin-top: 10px;
	justify-content: space-between;
}
.card__medical-table .card__medical-table-info {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.card__medical-table .card__medical-table-tag .span {
	background: #00a585;
	border-radius: 15px;
	padding: 1px 8px;
	margin-top: 4px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-size: 14px;
	line-height: 12px;
	letter-spacing: 1px;
	width: 25%;
}
.card__medical-table .card__medical-table-image {
	width: 25%;
	justify-content: right;
}
.card__medical-table .card__medical-table-image .img {
	float: right;
}
.avatar {
	border-radius: 50% !important;
	overflow: hidden;
	max-width: 70px;
	min-height: 70px;
	max-height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.background {
	background-image: url('../../../img/Vector.svg'), linear-gradient(347.42deg, #8fd3f4 41.85%, #cbffff 106.84%);
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}
.mdContent {
	background: url(../../../img/Vector.svg);
}
.article-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
}
.flip {
	color: black;
	font-weight: 500;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 5px;
	font-size: small;
}
.flip ion-icon {
	padding-right: 3px;
}
.mlFront,
.mlBack {
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 0;
}

.mlBack {
	background-image: url('../../../img/Vector.svg'), linear-gradient(347.42deg, #8fd3f4 41.85%, #cbffff 106.84%);
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.mlNoRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}

.title-license-expiration .datetime {
	padding: 3px !important;
}

.card__face_zoom {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	backface-visibility: hidden;
}
.width-med-lic {
	width: 100%;
}
.container-row-med-lic {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
.container-med-lic-data {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;
	margin-left: 10px;
	margin-right: 10px;
}
.margin-top-med-lic-content {
	margin-top: 30px !important;
}
.modal-img-license {
	min-width: 300px;
}
.margin-activate-ml {
	margin-top: 40px;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
}
