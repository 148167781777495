.card__board-actions-body {
	padding-top: 30px;
	color: #fff;
}
.card__board-actions-body {
	padding: 15px;
	color: #fff;
	display: flex;
	flex-direction: column;
}
.card__board-actions-body ion-text.span {
	padding: 5px 0;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	font-size: 13px;
}
.card__board-actions-body ion-text.detail {
	font-weight: 700;
}
.card__board-actions-body ion-text.span .datetime {
	padding: 0;
	padding-left: 3px !important;
}
.card__board-actions-footer {
	padding-top: 10px;
	color: #fff;
	display: flex;
	justify-content: center;
	width: 100%;
}
.card__board-actions {
	background-image: linear-gradient(158.83deg, #ffbe00 1.3%, #ffbe00 100%);
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}
.article-header__board-actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
}

.dlFront,
.dlBack {
	position: absolute;
	display: flex;
	justify-content: center;
	top: 0;
}
.background-ba {
	background-image: linear-gradient(158.83deg, #ffbe00 1.3%, #ffbe00 100%);
}
.dlBack {
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.dlNoRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}

.content-card-board-actions ion-text.span {
	display: block;
	font-size: 16px;
	color: #25282b;
	font-weight: 700;
}
.content-card-board-actions {
	width: 100%;
	display: flex;
	justify-content: center;
}
.flex-col {
	display: flex;
	flex-direction: column;
}
.content-table ion-col ion-text {
	font-family: var(--ion-font-family3);
	font-size: 12px;
	line-height: 14.4px;
}

.lato-font {
	font-family: var(--ion-font-family3);
}
.content-text-board-actions {
	font-family: var(--ion-font-family3);
	font-size: 16px;
	font-weight: 400;
	color: #25282b;
	line-height: 16px;
	padding-bottom: 10px;
}
.title-content-text {
	font-family: var(--ion-font-family3);
	color: #ff6760;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.board-actions__clear-content {
	font-family: var(--ion-font-family3);
	font-weight: 900;
	font-size: 14px;
	cursor: pointer;
	line-height: 16px;
	color: #25282b;
	padding: 0.5em 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.all-clear-title-board {
	font-family: var(--ion-font-family3);
	font-weight: 900;
	font-size: 17px;
	color: #25282b;
	padding-left: 10px;
	align-items: center;
	display: flex;
}
.all-clear-subtitle {
	font-family: var(--ion-font-family3);
	font-weight: 400;
	font-size: 17px;
	color: #25282b;
	line-height: 20.4px;
	padding-top: 8px;
	padding-bottom: 30px;
}
.what-is-this__title {
	font-family: var(--ion-font-family3);
	font-weight: 700;
	font-size: 14px;
	color: #1f174f;
	line-height: 14.4px;
}
.what-is-this__content {
	font-family: var(--ion-font-family3);
	font-weight: 400;
	font-size: 12px;
	color: #1f174f;
	line-height: 14.4px;
	padding-bottom: 10px;
}
.padding-content {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
}
.last-checked ion-text {
	color: #25282b;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}
.last-checked ion-text.span {
	font-weight: 400;
	padding-left: 5px;
}
.on-off-label {
	color: #25282b;
	font-weight: 400;
	font-size: 14px !important;
}

.content-board-actions-front {
	overflow-y: auto;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
}

.bubble-board-board-actions {
	flex-grow: 0;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: inline-flex;
	background: #ff6760;
	padding: 0;
	margin-left: 15px;
}

.bubble-board-board-actions span {
	font-family: var(--ion-font-family3);
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}

.description-black-sam {
	color: black;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	padding-left: 15px;
}
.toggle {
	zoom: 0.7;
	align-content: center;
}
.monitoring-row-board-actions {
	flex-direction: column;
	width: 100%;
	align-items: center;
}
.baLogo {
	position: absolute;
	right: 0;
	width: 60%;
	z-index: -1;
}
.wrapper-zoom-board {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.order-date-title-actions {
	color: #25282b;
	font-weight: 300;
	font-size: 14px;
	line-height: 12px;
}
.icon-about-board-actions {
	width: 24px;
	height: 24px;
}
.all-clear-content-board {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}
.bdac-message {
	position: absolute;
	bottom: 50%;
	font-size: 20px;
	font-weight: 500;
	margin: 10px;
}
.img-board-payment {
	height: 120px;
}
