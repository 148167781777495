@media screen and (max-width: 370px) {
	.centerBtnText span {
		text-align: center !important;
		display: block !important;
	}
}
@media screen and (min-width: 371px) and (max-width: 390px) {
	.centerBtnText span {
		text-align: center !important;
		display: contents !important;
	}
}
@media screen and (min-width: 391px) and (max-width: 540px) {
	.centerBtnText span {
		text-align: center !important;
		display: block !important;
	}
}
@media screen and (min-width: 541) and (max-width: 912px) {
	.centerBtnText span {
		text-align: center !important;
		display: contents !important;
	}
}
@media screen and (min-width: 913px) {
	.centerBtnText span {
		text-align: center !important;
		display: block !important;
	}
}
.submitBtnTask {
	background-color: #336cfb !important;
	color: white !important;
	border-radius: 10px !important;
	margin: 5px !important;
	justify-content: center !important;
	width: 100%;
	text-transform: none !important;
}
.cancelBtnTask {
	background-color: #336cfb !important;
	color: white !important;
	border-radius: 10px !important;
	margin: 5px !important;
	justify-content: center !important;
	width: 100%;
	text-transform: none !important;
}
.custom-class-task .alert-button-group {
	justify-content: center;
}
.custom-class-task .alert-button-inner {
	justify-content: center;
}
.custom-class-task .alert-head {
	text-align: center;
	color: #25282b;
	font-size: 20px;
	font-weight: 700;
}
.custom-class-task .alert-message {
	color: #25282b;
	font-size: 14px;
	font-weight: 400;
}
.submitBtnGeneric {
	background-color: #336cfb !important;
	color: white !important;
	border-radius: 10px !important;
	margin: 5px !important;
	justify-content: center !important;
	text-transform: none !important;
}
.cancelAlertSam {
	background-color: #47a38b !important;
	color: white !important;
	border-radius: 10px !important;
	margin: 5px !important;
	justify-content: center !important;
	width: 100%;
	text-transform: none !important;
}
.my-custom-class .alert-wrapper .sc-ion-alert-md {
	--border-radius: 15px !important;
}
.custom-class-task .alert-wrapper {
	padding: 10px !important;
}
