.save-row-started {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.or {
	width: 100%;
	justify-content: center;
	text-align: center;
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 700;
	font-size: large;
}
.input-states {
	background: transparent;
	border: none;
}
textarea:focus,
input:focus {
	outline: none;
}
.select-state-started {
	width: 100%;
	--placeholder-color: black;
	--placeholder-opacity: 1 !important;
}
