.wrapper-zoom-passwords {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.wrapper-passwords {
	min-height: calc(100% - 120px);
	position: relative;
}
.row-title-password {
	font-size: 20px;
	color: #ffffff;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
}
.card-password {
	transform-style: preserve-3d;
	transform-origin: center;
	transition: transform 1s;
}
.card-password-pending {
	background-image: url('../../img/image 30.png'), linear-gradient(145.35deg, #5bfab7 12.4%, #039f67 94.48%);
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-size: 100%;
}
.row-btn-pass {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	margin-right: 10px;
}
.icon-pass {
	width: 50px;
	height: 50px;
	background: #336cfb;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.container-btn-pass {
	display: flex;
	justify-content: space-evenly;
	padding: 10px;
}
.footer-pass-container {
	color: white;
	width: 100%;
	height: 80px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	justify-content: center;
}
.margin-top-pass-card {
	margin-top: 30px;
}
