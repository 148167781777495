.saveRow {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.result-card {
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: left;
	text-align: left;
	border-radius: 15px;
	background: white;
	color: black;
	padding-top: 10px;
	margin: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
}
.row-card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: left;
	text-align: left;
	width: 80%;
	padding-left: 20px;
	padding-right: 20px;
}
.saveTermsCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 20%;
}
.buttonCardWIY {
	width: 100%;
	font-size: small;
}
.selected {
	background: #59c5c7;
	color: white;
	justify-content: space-around;
	padding-left: 0vw;
}
.cards-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.results-label {
	background: #ff6760;
	width: 200px;
	height: 20px;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	color: white;
	font-weight: bold;
}
.result-card-title {
	font-size: 20px;
	line-height: 26px;
	font-weight: 700;
}
.result-card-sub-title {
	font-size: 16px;
	line-height: 19px;
	font-weight: 400;
	color: grey;
}
.result-card-npi {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
}
