.code-label {
	font-weight: 800;
}
.grey {
	color: grey;
}
.check-email-text {
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #00a585;
}
.input-code-verify {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 60px;
	margin: 10px;
	text-align: center;
}
.row-input-code-verify {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.flex-center-verify-email {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.send-code-again-color {
	color: #00a585;
	font-size: 14px;
	font-weight: 700;
}
