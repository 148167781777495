@media screen and (min-width: 1100px) {
	.popover-css {
		--width: 25% !important;
	}
}

@media screen and (max-width: 1099px) and (min-width: 801px) {
	.popover-css {
		--width: 30% !important;
	}
}
@media screen and (max-width: 800px) and (min-width: 500px) {
	.popover-css {
		--width: 50% !important;
	}
}
@media screen and (max-width: 499px) {
	.popover-css {
		--width: 90% !important;
	}
}
.container-img-text-center {
	position: relative;
	text-align: center;
	color: white;
}
.image-text-center {
	position: absolute;
	font-family: var(--ion-font-family2);
	top: 50%;
	left: 50%;
	text-align: left;
	width: 90%;
	transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: 700;
}
.bg-progress-bar {
	justify-content: center;
	margin: 0 auto;
	width: 98%;
	height: 30px;
	display: flex;
	border-radius: 15px;
	align-items: center;
	box-shadow: 1px 3px 7px #bdd0fc;
}

.wwn-title {
	font-family: var(--ion-font-family2);
}
.date-title {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	font-weight: 500;
	font-family: var(--ion-font-family);
}
.date-title span {
	padding: 0 10px;
}
.wwn-subtitle {
	padding-top: 20px;
	font-family: var(--ion-font-family);
	text-transform: capitalize;
	color: #000;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}
.tooltip-progress-bar {
	margin-top: 40px;
	width: 100%;
	position: relative;
}
.tooltip-progress-bar .tooltip-marker {
	position: relative;
	left: -35px;
	top: -10px;
	background: var(--ion-color-dark);
	font-family: var(--ion-font-family2);
	font-weight: 700;
	padding: 5px 3px;
	text-align: center;
	font-size: 20px;
	width: 70px;
	color: #fff;
	border-radius: 7px;
}
.tooltip-progress-bar .tooltip-marker span {
	position: relative;
	/* this is just for Safari */
}
.tooltip-progress-bar .tooltip-marker span:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: '';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: var(--ion-color-dark);
	border-width: 15px;
	margin-left: -15px;
}
.tasks-title-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 14px;
	color: #a0a4a8;
	padding-bottom: 10px;
}
.number-circle-tasks {
	flex-grow: 0;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: inline-flex;
	background: #ff7e77;
	padding: 0;
	margin-left: 10px;
}
.number-circle-tasks span {
	font-family: var(--ion-font-family3);
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
.row-footer-tasks {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	color: #336cfb;
	font-weight: bold;
}
.generic-info-message {
	display: flex;
	justify-content: center;
	font-size: 18px;
	margin-left: 10px;
	margin-right: 10px;
}
.css-modal {
	--width: 40%;
	--border-radius: 10px;
	--height: 40%;
}
.row-footer-payer {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	color: #000000;
	font-weight: bold;
	font-size: 14px;
}
.section-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #dadada;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 40px;
	padding-top: 5px;
}
.text-do-it-later {
	font-family: Rubik;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.2px;
	color: #52575c;
}
.text-needed {
	display: flex;
	justify-content: center;
	background: #e8e8e8;
	border-radius: 25px;
	width: 60%;
	font-size: 14px;
	color: #616161;
	margin-left: 26px;
	margin-right: 29px;
	margin-bottom: 30px;
	margin-bottom: 30px;
	align-items: center;
	height: 30px;
}
.text-message {
	color: #25282b;
	font-size: 14px;
	margin-top: 17px;
	margin-left: 26px;
	margin-right: 29px;
	margin-bottom: 20px;
}
.text-title {
	color: #25282b;
	font-size: 20px;
	font-weight: bold;
	margin-top: 17px;
	margin-left: 26px;
	margin-right: 29px;
	margin-bottom: 20px;
	align-items: center;
	text-align: center;
}
.div-padding-needed {
	padding-bottom: 80px;
}
.content-modal-div {
	margin-top: 10px;
	margin-left: 20px;
	margin-right: 20px;
}
.popover-css {
	--max-height: 90%;
	--width: 90%;
}
.css-animation {
	animation: mymove 1s;
}
@keyframes mymove {
	0% {
		bottom: 50px;
	}
	100% {
		bottom: -25px;
	}
}
ion-card.regular-card ion-card-header {
	margin-top: 0px;
}
.text-instructions-generic {
	text-align: center;
	display: flex;
	flex-direction: column;
}
.no-content-task-selected {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.title-text-tasks {
	font-size: 20px;
	font-weight: 700;
}
.back-tasks {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	justify-content: center;
}

.bg-schedule-modal {
	background-color: rgba(0, 0, 0, 0.2);
	width: 100vw;
	height: 100vh;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}

.centered-scheduled-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.modal-schedule {
	width: 315px;
	background: white;
	color: white;
	z-index: 10;
	border-radius: 16px;
	box-shadow: 0 5px 20px 0 rgb(0 0 0 / 4%);
}

.modal-header-schedule {
	height: 50px;
	background: white;
	overflow: hidden;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.heading-schedule {
	margin: 0;
	padding: 10px;
	color: #2c3e50;
	font-weight: 700;
	font-size: 20px;
	text-align: center;
}

.modal-content-schedule {
	padding: 10px;
	font-size: 14px;
	color: #2c3e50;
	text-align: center;
	display: flex;
	flex-direction: column;
}

.actions-container-schedule {
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.close-btn-schedule {
	cursor: pointer;
	font-weight: 500;
	padding: 4px 8px;
	border-radius: 8px;
	border: none;
	font-size: 21px;
	color: #2c3e50;
	transition: all 0.25s ease;
	position: absolute;
	right: 0;
	top: 0;
	align-self: flex-end;
	margin-top: -40px;
	margin-right: 0px;
}

.close-btn-schedule:hover {
	transform: translate(-4px, 4px);
}
.flex-col-schedule {
	display: flex;
	flex-direction: column;
}
.schedule-title-desc {
	font-size: 16px;
}
.btn-text-document-schedule {
	font-size: 14px;
	font-weight: 700;
}
.center-no-info-card-tasks {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}
