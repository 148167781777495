.img-driver-license {
	cursor: pointer;
}
.card__drivers-license-header {
	width: 100%;
	justify-content: center;
}
.img-driver-license-default {
	cursor: pointer;
	width: 250px;
	border-radius: 15px;
	height: 140px;
	border: 2px dashed white;
	display: flex;
	justify-content: center;
	align-items: center;
}
.img-driver-license ion-img {
	max-width: 250px;
	border: 4px solid #fff;
	border-radius: 15px;
	max-height: 135px;
}
.img-driver-license-default .default-row ion-icon {
	width: 70px;
	height: 70px;
}
.img-driver-license-default .default-row {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	color: white;
}
.card__drivers-license-body {
	padding-top: 30px;
	color: #fff;
}
.card__drivers-license-body {
	padding: 15px;
	color: #fff;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.card__drivers-license-body ion-text.span {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}
.card__drivers-license-body ion-text.detail {
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
}
.card__drivers-license-body ion-text.span .datetime {
	padding: 0;
	padding-left: 3px !important;
}
.card__drivers-license-footer {
	padding-top: 10px;
	color: #fff;
	display: flex;
	justify-content: center;
	width: 100%;
}
.card__drivers-license {
	background-image: linear-gradient(158.83deg, #ff6760 1.3%, #e74f48 100%);
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}

.article-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
}

.dlFront,
.dlBack {
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 0;
}

.dlBack {
	background-image: linear-gradient(158.83deg, #ff6760 1.3%, #e74f48 100%);
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.dlNoRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}
.border-driv-license {
	border: solid 1px;
}
.margin-bottom-drv-content {
	margin-bottom: 5px;
}
.margin-top-drv-content {
	margin-top: 5px;
}
.card-face-drv-license {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	backface-visibility: hidden;
}
.edit-driv-license-image {
	width: 40px;
	height: 40px;
	position: absolute;
	justify-content: center;
	align-items: center;
	bottom: 0px;
	right: -17px;
	background: #336cfb;
	border-radius: 50%;
}
.container-edit-dr-license {
	position: relative;
	justify-content: flex-end;
}
