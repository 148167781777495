.InputSender {
	background: #52575c;
	padding: 7px 7px 7px 11px;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	max-height: 125px;
	height: 100%;
}

.InputSender .input {
	background: #ffffff;
	height: 40px;
	color: black;
	font-weight: 400;
	padding: 12px 20px;
	max-height: 102px;
	height: 100%;
	outline: none;
	overflow: scroll;
	border-radius: 10px;
}

.InputSender [contentEditable='true']:empty:not(:focus):before {
	content: attr(data-ph);
	font-size: 14px;
	color: #c6c8cb;
	font-weight: 400;
}

.InputSender .document {
	height: 18px;
	width: 18px;
}

.InputSender .documentContainer {
	align-self: center;
}

.InputSender .buttonSenderIcon {
	width: 100%;
	height: 36px;
	margin-right: 0px;
}

.InputSender .buttonSenderIconContainer {
	align-self: center;
}
