@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap');

.card .category-title {
	font-size: 20px;
	text-align: left;
	font-weight: 500;
	color: var(--ion-color-dark);
	margin-bottom: 1rem;
	margin-left: 0.6rem;
}

.card .content-card {
	padding: 6px;
	padding-top: 0;
}
.card-custom {
	transform-origin: center top !important;
}
.card.card__medical-license .category-title {
	color: #169496;
	font-size: 20px;
}

.card.card__medicare .category-title {
	color: #fff;
	font-size: 20px;
}

.card.card__drivers-license .category-title {
	color: #fff;
	font-size: 20px;
}

.card.card__my-payers .category-title {
	color: #52575c;
	letter-spacing: 1px;
	font-size: 20px;
}
.card.card__dea-license .category-title {
	font-weight: 500;
	color: #25282b;
	font-size: 20px;
}

.card.card__credentialing-logins .category-title {
	color: #fff;
	font-size: 20px;
}
.card.card__nppes .category-title {
	color: #fff;
	font-weight: 500;
	font-size: 20px;
}
.card.card__leie .category-title {
	color: #75b4f1;
	font-weight: 500;
	font-size: 20px;
}
.card.card__aoa .category-title {
	color: #0c2d65;
	font-weight: 500;
	font-size: 20px;
}
.card.card__board-actions .category-title {
	color: #000;
	font-weight: 500;
	font-size: 20px;
}
.card.card__abms .category-title {
	color: #65112b;
	font-weight: 500;
	font-size: 20px;
}

.card.card__medicare-pending .category-title {
	color: #fff;
	font-weight: 500;
	font-size: 20px;
}

.card.card__medicaid-pending .category-title {
	font-weight: 500;
	color: rgba(255, 255, 255, 1);
	font-size: 20px;
}

.card.card__sam-exclusion .category-title {
	color: #000;
	font-weight: 500;
	font-size: 20px;
}

.app-opened {
	box-sizing: border-box;
	overflow: scroll;
	background: transparent;
	z-index: 100;
	top: 10px;
	animation: zoom-in 0.5s ease-out;
}
.closing {
	position: absolute;
	box-sizing: border-box;
	overflow: scroll;
	background: transparent;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 10px;
	animation: zoom-out 1s ease-out;
}

@keyframes zoom-in {
	0% {
		transform: scale(0.1);
	}

	100% {
		transform: scale(1);
	}
}
@keyframes zoom-out {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}
.wallet-container {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.image-container {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 66.66%;
	cursor: zoom-in;
	margin: 50px 0;
}

.image-container.open {
	cursor: zoom-out;
}

.image-container .shade {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	opacity: 0;
	background: rgba(0, 0, 0, 0.9);
}

.image-container.open .shade {
	pointer-events: auto;
	opacity: 1;
}

.image-container img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.image-container.open img {
	position: fixed;
	width: auto;
	height: auto;
	max-width: 100%;
	margin: auto;
}

@media (max-width: 600px) {
	article {
		padding: 20px;
	}
}
.header-cardSelected {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 92vw;
	max-width: 550px;
}
.credDoneButton {
	color: black;
	font-weight: 500;
}
.swiper-slide {
	display: flex;
	justify-content: center;
	height: auto;
}
.swiper-content {
	display: contents;
}
.scroll-div-cred {
	overflow-y: auto;
}
.header-cred-web {
	background-color: lightgray;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
}
.cred-white-text {
	color: white;
}
.header-title-web {
	font-size: 20px;
}
.header-title-close-web {
	font-size: 16px;
}
