.count-exclusion {
	flex-grow: 0;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: inline-flex;
	padding: 0;
}
.count-exclusion-circle {
	font-family: var(--ion-font-family3);
	font-size: 14px;
	font-weight: 700;
	height: 100%;
	width: 100%;
	display: flex;
	color: black;
	margin: auto;
	justify-content: center;
	align-items: center;
}
.exc-found-color {
	background: #f05851;
}
.exc-possible-color {
	background: #fac032;
}
.exc-prev-match-color {
	background: #ffffff;
}
.full-row-leie {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.exclusion-name-leie {
	font-weight: 700;
	font-size: 14px;
	color: #000000;
	margin-left: 10px;
}
.exclusion-data-leie {
	font-size: 14px;
	color: #000000;
	margin-left: 10px;
	padding-top: 5px;
}
.row-content-exc {
	background: #d9d9d9;
	border-radius: 10px;
	padding: 5px;
}
