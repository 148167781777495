ion-content.background-sign-in {
	--background: #004734;
	max-width: 550px;
}
ion-content.background-payment {
	--background: white;
	max-width: 550px;
}
.title-payment {
	font-weight: 700;
	color: white;
	font-size: 20px;
}
.text-credential-payment {
	text-align: center;
	padding: 10px;
	color: white;
	font-size: 14px;
}
.flex-center-subs-amount {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.sign-in-width-subs {
	width: 100%;
	--color: #3c4257;
}
ion-button.payment-button::part(native) {
	background: #3c4257;
}
.line-left-payment {
	position: absolute;
	left: 20px;
	transform: translateY(-50%);
}
.container-benefits-payment {
	display: flex;
	text-align: left;
	margin-bottom: 10px;
	color: white;
}
.description-payment {
	width: 90%;
}
.line-rigth-payment {
	position: absolute;
	right: 20px;
	transform: translateY(-50%);
}

.style-div-save {
	display: flex;
	width: 100%;
	align-items: center;
}

.span-monitoring {
	text-decoration: underline #00a585;
	font-weight: 700;
	color: #fbc132;
}

.monitoring-text {
	text-align: center;
	padding: 10px;
	color: #fbc132;
	font-size: 14px;
}
.main-content-payment {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.content-payment {
	display: flex;
	align-items: center;
	background: #e9e8ff;
	border-radius: 10px;
	height: 80px;
	width: 100%;
}
.amount-weigth-payment {
	font-weight: 700;
	color: white;
}
.icon-option-payment {
	width: 20px;
	height: 20px;
	margin-left: 10px;
	margin-right: 10px;
}
.payment-popular-text {
	background: #336cfb;
	border-radius: 5px;
	width: 90%;
	text-align: center;
	font-size: 14px;
	color: white;
	font-weight: 700;
	padding: 5px;
	position: relative;
	top: 13px;
}
.border-selected-payment {
	border: solid 1px;
	border-color: #336cfb;
}
.reviews-payment {
	display: flex;
	width: 100%;
	justify-content: center;
}
.read-reviews-content {
	font-size: 14px;
	padding: 5px;
	color: white;
}
.reviews-text-color {
	color: #00a585;
}
.list-container-payment {
	max-height: 200px;
	overflow-y: auto;
	border: 1px solid #2a3b42;
	border-radius: 10px 10px 0 0;
	background-color: #2a3b42;
	padding: 20px;
}
.type-payment {
	width: 100%;
	height: 100vh;
	background-image: url('../../img/plan_active.svg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
}

.data-payment {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.width-container {
	width: 90%;
}
.width-container-full {
	width: 100%;
}
.container-data-payment {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	top: -65px;
}

.data-payment-month {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.subs-gap {
	gap: 15px;
}
.monitoring-text-get {
	text-align: center;
	padding: 10px;
	color: white;
	font-size: 18px;
}
ion-modal#pay-version-modal {
	--height: 60%;
	--border-radius: 16px;
	--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#pay-version-modal::part(backdrop) {
	background: white;
	opacity: 1;
}

.pay-version-modal img,
.pay-version-modal ion-img {
	width: 40%;
	margin: 0 auto;
	padding-top: 35px;
	padding-bottom: 35px;
}

.pay-version-title {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.2px;
	color: #1a1f36;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}
.email-payment {
	margin-top: 30px;
	padding-left: 5px;
	color: #697386;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}
.email-value-payment {
	border: solid 1px #697386;
	border-radius: 10px;
	font-size: 20px;
	margin: 10px;
}
.email-padding-payment {
	padding: 10px !important;
}
.error-payment {
	margin-top: 20px;
	color: red;
	font-size: 20px;
}
.border-zip-payment {
	border-top: solid 1px #697386;
}
.pay-version-text {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	margin-top: 70px;
	display: flex;
	justify-content: center;
	color: #697386;
	text-align: center;
	letter-spacing: 0.1px;
}
.exp-payment {
	width: 50%;
	padding: 10px;
	border-top: solid 1px;
}
.cvc-payment {
	width: 50%;
	padding: 10px;
	border-top: solid 1px;
	border-left: solid 1px;
}
.back-payment {
	display: flex;
	align-items: center;
	padding: 10px;
}
.card-number-payment {
	padding: 10px;
}
.container-exp-cvc {
	display: flex;
}
.container-close-modal {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}
.img-wallet-payment {
	width: 150px;
	position: relative;
	z-index: 10000;
	height: 150px;
}
.img-wallet-payment-bg {
	width: 200px;
	position: absolute;
	top: 40px;
	height: 160px;
	top: 115px;
}
.close-modal-img {
	width: 25px !important;
	position: relative;
	right: 5px;
}
.container-save-money {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #00a58524;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	width: 80%;
	position: relative;
	top: -3px;
	padding: 5px;
	height: 40px;
}
.clock-discount {
	width: 40px;
	height: 25px;
}
@media screen and (max-width: 375px) {
	.line {
		position: relative;
		height: 1px;
		border: none;
		border-top: 1px solid #00a585;
		margin: 0 auto;
		width: 20%;
	}

	.line-left-payment {
		position: absolute;
		left: 5px;
		transform: translateY(-50%);
	}

	.line-rigth-payment {
		position: absolute;
		right: 5px;
		transform: translateY(-50%);
	}
}
.div-half-size {
	width: 30%;
}
.div-btn-size {
	width: 40%;
	text-align: center;
}

@media screen and (min-width: 360px) {
	.line {
		position: relative;
		height: 1px;
		border: none;
		border-top: 1px solid #00a585;
		margin: 0 auto;
		width: 20%;
	}

	.line-left-payment {
		position: absolute;
		left: 5px;
		transform: translateY(-50%);
	}

	.line-rigth-payment {
		position: absolute;
		right: 5px;
		transform: translateY(-50%);
	}
}

@media screen and (min-width: 412) and (max-width: 539px) {
	.container-data-payment {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		top: -69px;
	}
}
@media screen and (min-width: 540px) {
	.container-data-payment {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		top: -90px;
	}
}
