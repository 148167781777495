.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
}
ion-modal#app-version-modal {
	--height: 60%;
	--border-radius: 16px;
	--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#app-version-modal::part(backdrop) {
	background: white;
	opacity: 1;
}

.app-version-modal img,
.app-version-modal ion-img {
	width: 40%;
	margin: 0 auto;
	padding-top: 35px;
}

.app-version-modal .app-version-title {
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.2px;
	color: #25282b;
	margin: 30px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.app-version-modal .app-version-text {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	/* or 125% */

	text-align: center;
	letter-spacing: 0.1px;

	/* Neutral / Black */

	color: #25282b;
	margin-bottom: 30px;
}
.timer-white {
	color: white;
}
