.row-title-input {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #a0a4a8;
}
.input-position-password {
	margin-left: -15px;
	font-size: 14px;
	color: #000000;
	font-weight: 400;
}
.row-margin-input {
	margin-top: 20px;
}
.label-authorize-pass {
	width: 100%;
	font-size: 13px;
	color: #a0a4a8;
}
.toggle-margin-pass {
	padding-right: 10px !important;
}
.permission-to-log-in {
	font-size: 14px;
	color: #52575c;
	padding-bottom: 10px;
}
.margin-rigth-paswords {
	margin-right: 10px;
}
.edit-pass-labels {
	font-size: 14px;
	font-weight: 400;
	color: #a0a4a8;
}
.ion-pass-margin-top {
	margin-top: 5px;
}
.cancel-ion-button {
	--color: #25282b;
	font-weight: 700;
	font-size: 14px;
}
.btn-passwords-width {
	width: 48%;
}
.acc-password-input {
	width: 100%;
}
.acc-password-input::part(native) {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
}
.content-password-update {
	display: flex;
	align-items: center;
}
.password-icon {
	padding-left: 5px;
}
