.accordion-wrapper + * {
	margin-top: 0em;
}
.accordion-item-dea {
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
	height: auto;
	max-height: 9999px;
	margin-bottom: 10px;
}
.accordion-item-dea.collapsed {
	max-height: 0;
	transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
	font-family: var(--ion-font-family3);
	font-weight: 900;
	font-size: 12px;
	cursor: pointer;
	line-height: 14px;
	color: #25282b;
	padding: 0.5em 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.uppercase-title {
	text-transform: uppercase;
}
.accordion-title:hover,
.accordion-title.open {
	color: black;
}
.accordion-content {
	padding: 0 1.5em 1.5em 1.5em;
}
.accordion-title ion-text {
	max-width: 200px;
}
.card-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
}
.card-row ion-col.center-text-col {
	text-align: center;
}
.card-row ion-col.center-icon-col ion-img {
	margin: 0 auto;
}

ion-img.row-icon {
	width: 16px;
	height: 10px;
}

.row-text-title-aco-dea {
	font-size: 20px;
	font-weight: 700;
	color: #000;
	text-transform: capitalize;
}

.row-text-active-aco-dea {
	color: #ffffff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	border-radius: 40px;
	background: #16d090;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.border-top-dea {
	border-top: 1px solid #a57e40;
}
.border-bottom-dea {
	border-bottom: 1px solid #a57e40;
}
.full-width-accordion-dea {
	width: 100%;
}
