.card__medicare-pending-body {
	padding-top: 30px;
	color: #fff;
}
.card__medicare-pending-body {
	padding: 15px;
	color: #fff;
	display: flex;
	flex-direction: column;
}
.card__medicare-pending-body ion-text.span {
	padding: 5px 0;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	font-size: 13px;
}
.card__medicare-pending-body ion-text.detail {
	font-weight: 700;
}
.card__medicare-pending-body ion-text.span .datetime {
	padding: 0;
	padding-left: 3px !important;
}
.card__medicare-pending-footer {
	padding-top: 10px;
	color: #fff;
	display: flex;
	justify-content: center;
	width: 100%;
}
.card__medicare-pending {
	background-image: url('../../../img/Medicare-Symbol2.png'),
		linear-gradient(160.52deg, #336cfb 12.11%, #092e86 112.76%);
	background-position: bottom right;
	background-repeat: no-repeat;
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.article-header__medicare-pending {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
}
.mcareFront,
.mcareBack {
	display: flex;
	justify-content: center;
	top: 0;
}
.mcareBack {
	background-image: url('../../../img/Medicare-Symbol2.png'),
		linear-gradient(160.52deg, #336cfb 12.11%, #092e86 112.76%);
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	min-height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.mcareNoRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}
.mcare-content-number {
	display: block;
	font-size: 14px;
	color: #ffffff;
	font-weight: 700;
}
.mcare-content-card-title {
	font-size: 14px;
	color: #ffffff;
	font-weight: 400;
	padding-top: 8px;
	line-height: 16px;
	margin-bottom: 10px;
}
.flex-col {
	display: flex;
	flex-direction: column;
}
.mcare-row .datetime {
	padding: 0px !important;
	display: block;
	font-size: 13px;
	color: #25282b;
	font-weight: 500;
}
.mcare-close-card {
	width: 100%;
	justify-content: center;
	padding: 10px;
	color: white;
}
.card-medicaresymbol {
	position: absolute;
	width: 40px;
	height: 38px;
	left: 76px;
	opacity: 0.4;
}
.card-questionmarksymbol {
	position: absolute;
	width: 18px;
	height: 16px;
	left: 261px;
	top: 13px;
}
.card-medicaresymbol2 {
	position: absolute;
	width: 334.03px;
	height: 246.3px;
	left: -25.71px;
	top: 185.18px;
	opacity: 0.017;
}
.row-text_title_medicaid {
	font-size: 20px;
	font-weight: 700;
	color: white;
	text-transform: capitalize;
	line-height: 26px;
	font-weight: bold;
}
.badge-medicare {
	border-radius: 25px;
	border: solid white 1px;
	text-align: center;
	width: 95px;
}
.badge1 {
	border-radius: 25px;
	border: solid white 1px;
	padding: 5px;
	text-align: center;
	margin: 4px 2px;
}
.row-title-medicare {
	font-size: 20px;
	color: #ffffff;
	font-weight: bold;
	padding-left: 20px;
}
.card__medicare-pending-header {
	width: 100%;
	margin-top: 42px;
}
.renewal-date-title {
	font-size: 12px;
	color: rgba(117, 180, 241, 1);
	font-weight: 700;
	padding-top: 8px;
	line-height: 16px;
	text-align: center;
}
.renewal-date-value {
	display: block;
	font-size: 12px;
	color: white;
	font-weight: 500;
}
.row-content-data-mcare {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}
.row-medicare-data {
	display: flex;
	flex-direction: column;
}
.flex-col-row {
	display: flex;
	flex-direction: column;
}
.value-text-dates-medicare {
	display: block;
	font-size: 14px;
	color: #ffffff;
	font-weight: 700;
}
.default-screen-medicare {
	display: flex !important;
	flex-direction: column !important;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 20px;
	font-size: 14px;
	font-weight: bold;
	color: #ffffff;
}
.clockIcon-medicare {
	min-height: 100px;
	min-width: 100px;
}
.medicare-width {
	width: 100%;
}
.wrapper-mcare {
	min-height: calc(100% - 150px);
	position: relative;
}
.wrapper-zoom-mcare {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.icon-about-medicare {
	width: 24px;
	height: 24px;
	position: absolute;
	right: 15px;
}
.medicare-padding {
	padding-bottom: 5px;
	padding-left: 5px;
}
.bubble-mcare {
	flex-grow: 0;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: inline-flex;
	background: #ff0000;
	padding: 0;
	margin-left: 30px;
}

.bubble-mcare span {
	font-family: var(--ion-font-family3);
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
.margin-top-mcare-content {
	margin-top: 30px !important;
}
.center-no-info-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}
.text-info-card-medicare {
	position: absolute;
	bottom: 50%;
	font-size: 20px;
	color: #ffffff;
	margin: 10px;
}
