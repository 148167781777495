.card-about {
	transform-style: preserve-3d;
	transform-origin: center;
	transition: transform 1s;
}
.back-about {
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	width: 92vw;
	max-width: 400px;
	min-height: 455px;
	border-radius: 20px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.description-about {
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
}
.description-title {
	font-weight: 700;
	font-size: 16px;
}
.description-text {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}
.about-title {
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 30px;
	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.about-close-card {
	width: 100%;
	justify-content: center;
	padding: 10px;
	font-size: 16px;
}
.dea-background {
	background: linear-gradient(0.36deg, #ebcc98 0.46%, #fae7c4 99.84%);
}
.abms-background {
	background-image: linear-gradient(158.83deg, #ffeef3 1.3%, #ffa8c7 100%);
}
.board-act-background {
	background-image: linear-gradient(158.83deg, #ffbe00 1.3%, #ffbe00 100%);
}
.sam-background {
	background-image: linear-gradient(158.83deg, #ffffff 1.3%, #ffffff 100%);
}
.leie-background {
	background: linear-gradient(158.83deg, #2f4b79 1.3%, #091936 100%);
}
.passport-background {
	background-image: linear-gradient(158.83deg, #6360ff 1.3%, #2402f2 100%);
}
.driver-license-background {
	background-image: linear-gradient(158.83deg, #ff6760 1.3%, #e74f48 100%);
}
.nppes-background {
	background: linear-gradient(157.43deg, #0c398c 7.9%, #071342 78.54%);
}
.about-white {
	color: #ffffff;
	--spinner-color: #ffffff;
}
.about-black {
	color: black;
	--spinner-color: black;
}

.med-license-background {
	background: linear-gradient(347.42deg, #8fd3f4 41.85%, #cbffff 106.84%);
}
.leie-title-bg {
	background: #091936;
	border-radius: 20px 20px 0px 0px;
	border-bottom: 2px solid #75b4f1;
}
