 .row-logo-payer{
    width: 88px;
  }
  .flex-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 8px;
    border-bottom: 2px solid #DBDDE0;
  }
  .flex-item-left{
    flex: 15%;
    display: flex;
    justify-content: center;
  }
  .flex-item-left-open{
      flex: 15%;
      display: flex;
      justify-content: center;
    }
  .flex-item-right {
    flex: 85%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row-items-collapsed{
    padding-left: 10px;
  }
  .circle-status-pending{
    background: #FAC032;
    flex-grow: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-flex;
    padding: 0;  
  }
  .circle-status-active{
    background: #16D090;
    flex-grow: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-flex;
    padding: 0;
    }
  .circle-status-inactive{
    background: #E74F48;
    flex-grow: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-flex;
    padding: 0;
    }
    .align-center-row{
      display: flex;
    align-items: center;
    }
    .border-bottom-payer-accordeon-test{
      border-radius: 0px 0px 10px 10px;
      box-shadow: 0px 10px 25px rgba(16, 30, 115, 0.05);
    }
    .card-row-accordeon-payer{
      display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    }
    .accordeon-payer-width{
      width: 50%;    
      align-items: center;
    }
    .accordeon-payer-sapce-between{
      justify-content: space-between;
    }
    ion-card ion-card-content .payer-card-content{
      display: flex;
      flex-direction: column;
      width: 100% !important;
      max-width : 100% !important;
      margin: 0 !important;
    }
    .padding-row-accordion-payer{
      padding-left: 20px;
      padding-right: 20px;
    }