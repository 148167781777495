.upload-media-container-img-lic {
	width: 100%;
	border: 2px dashed #dbdde0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	width: 250px;
	height: 200px;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 20px;
}
.image-lic-selected {
	width: 100%;
	border: 1px solid #336cfb;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	max-height: 135px;
	max-width: 250px;
	padding: 10px;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 20px;
}
.upload-area-img-lic {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.edit-dr-lic-text {
	position: absolute;
	justify-content: center;
	align-items: center;
	bottom: -6px;
	right: 5px;
	background: #336cfb;
	border-radius: 10px;
	padding-left: 7px;
	padding-right: 7px;
	font-size: 12px;
	font-weight: 500;
	color: white;
}
.container-edit-dr-lic-text {
	position: relative;
	justify-content: flex-end;
}
.img-width-license {
	height: 100px;
	width: 180px;
}
.row-pics-images {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
