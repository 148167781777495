ion-modal#exp-reminder-modal {
	--height: 60%;
	--min-height: 15%;

	--border-radius: 16px;
	--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#exp-reminder-modal::part(backdrop) {
	background: white;
	opacity: 1;
}

.exp-reminder-modal .exp-reminder-title {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.2px;
	color: #25282b;
	margin: 15px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.close-help-modal-text {
	color: #336cfb;
	letter-spacing: 0.2px;
	font-weight: 700;
	font-size: 14px;
}

.exp-reminder-modal .exp-reminder-text {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	text-align: center;
	letter-spacing: 0.1px;
	color: #25282b;
	margin-bottom: 15px;
}
.no-bg-item-reminder {
	--background: none;
	--inner-border-width: 0 0 0 0 !important;
}
.hide-reminder-label {
	text-overflow: unset !important;
	white-space: unset !important;
	font-size: 12px !important;
}
.exp-space-between {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	height: 100%;
}
.close-btn-schedule {
	cursor: pointer;
	font-weight: 500;
	padding: 4px 8px;
	border-radius: 8px;
	border: none;
	font-size: 21px;
	color: #2c3e50;
	transition: all 0.25s ease;
	position: absolute;
	right: 0;
	top: 0;
	align-self: flex-end;
	margin-top: 0px;
	margin-right: 0px;
}
.exp-reminder-modal .exp-reminder-med-license {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	letter-spacing: 0.2px;
	color: #25282b;
	display: flex;
	align-items: center;
	justify-items: center;
}
.exp-reminder-modal .exp-reminder-text-med {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	display: flex;
	letter-spacing: 0.1px;
	color: #25282b;
	margin-bottom: 15px;
	align-items: center;
	justify-items: center;
}
