.card__dea-license-body {
	padding-top: 30px;
	color: #fff;
}
.card__dea-license-body {
	padding: 15px;
	color: #fff;
	display: flex;
	flex-direction: column;
}
.card__dea-license-body ion-text.span {
	padding: 5px 0;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	font-size: 13px;
}
.card__dea-license-body ion-text.detail {
	font-weight: 700;
}
.card__dea-license-body ion-text.span .datetime {
	padding: 0;
	padding-left: 3px !important;
}
.card__dea-license-footer {
	padding-top: 10px;
	color: #fff;
	display: flex;
	justify-content: center;
	width: 100%;
}
.card__dea-license {
	background: linear-gradient(0.36deg, #ebcc98 0.46%, #fae7c4 99.84%);
	border-radius: 20px;
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.container-dea {
	background-image: url('../../../img/dea-bg.svg');
	background-repeat: no-repeat;
	background-position: right 20px top 65px;
	min-height: 455px;
}
.article-header__dea-license {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
}

.deaFront,
.deaBack {
	position: absolute;
	display: flex;
	justify-content: center;
	top: 0;
}

.deaBack {
	background: linear-gradient(0.36deg, #ebcc98 0.46%, #fae7c4 99.84%);
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.deaNoRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}

.dea-content-card ion-text.span {
	display: block;
	font-size: 14px;
	color: #25282b;
	font-weight: 500;
	padding-left: 8px;
	line-height: 16px;
}
.dea-content-card ion-text.title-span {
	color: #3c3c3c;
	font-size: 16px;
	font-weight: 700;
	padding-top: 8px;
	padding-left: 8px;
	line-height: 18px;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.dea-row .datetime {
	display: block;
	font-size: 14px;
	color: #25282b;
	font-weight: 500;
	padding-left: 8px;
	line-height: 16px;
}
.dea-close-card {
	width: 100%;
	justify-content: center;
	padding: 10px;
	color: black;
}
.title-dea-card {
	font-weight: 700;
	color: #25282b;
	font-size: 20px;
	padding-left: 10px;
}
.bubble-dea {
	flex-grow: 0;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: inline-flex;
	background: #ff6760;
	padding: 0;
	margin-left: 15px;
}
.bubble-dea span {
	font-family: var(--ion-font-family3);
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
.icon-about-dea {
	width: 24px;
	height: 24px;
}
.row-content-dea {
	width: 100%;
	margin-top: 20px;
}
.text-title-content-dea-card {
	color: #3c3c3c;
	font-size: 14px;
	line-height: 20px;
}
.text-value-content-dea-card {
	font-size: 14px;
	color: #25282b;
	font-weight: bold;
	line-height: 14px;
	line-height: 20px;
}
.content-dea-card-exp-date {
	font-size: 14px;
	color: #25282b;
	font-weight: bold;
	line-height: 16px;
}
.wrapper-zoom-dea {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.article-header-dea {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-right: 15px;
}
.description-black-dea {
	color: black;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	padding-left: 15px;
}
.row-all-clear-dea {
	display: flex;
	justify-content: center;
}
.all-clear-icon-dea {
	width: 80px;
	height: 80px;
}
.all-clear-subtitle-dea {
	font-family: var(--ion-font-family3);
	font-weight: 400;
	font-size: 14px;
	color: #000000;
	line-height: 20.4px;
	padding-top: 8px;
}
.row-dea-one-card {
	display: flex;
	flex-direction: column;
	width: 60%;
}
.row-dea-title-one-card {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}
.logo-dea-bg {
	position: relative;
	display: flex;
	justify-content: right;
	width: 100%;
}
.logo-dea-no-data {
	position: absolute;
	right: 20px;
	bottom: 25px;
	z-index: -1;
}
.margin-top-dea-content {
	margin-top: 30px !important;
}
.text-info-card-dea {
	position: absolute;
	bottom: 50%;
	font-size: 20px;
	color: #000000;
	margin: 10px;
}
.container-dea-license-row-data {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.row-dea-one-card-img {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.upload-media-container-no-image-dea {
	width: 100%;
	border: 2px dashed #111b0d;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	height: 80px;
	margin: 5px;
}
.overflow-text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
	width: 100%;
}
.row-pics-dea-card {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background: white;
}
.img-radius-dea {
	border-radius: 5px;
}
.container-row-all-dea {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.content-data-all-dea {
	display: flex;
	flex-direction: column;
}
.container-no-border-dea-img {
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-radius: 5px;
	margin-top: 5px;
	background: white;
	margin-bottom: 5px;
}
.container-img-pdf-dea {
	border-radius: 10px 10px 0px 10px;
	box-shadow: 1px 0px 2px 7px rgb(0 0 0 / 14%);
	background: rgb(0 0 0 / 14%);
	width: 100%;
}
.container-edit-dea-attachment {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.edit-dea-attachment {
	width: 70%;
	margin-top: 14px;
	margin-bottom: 20px;
	border-radius: 0px 0px 10px 10px;
	display: flex;
	height: 20px;
	box-shadow: 1px 0px 2px 7px rgb(0 0 0 / 14%);
	position: relative;
	background: rgb(0 0 0 / 14%);
}
.div-edit-attachment-container {
	display: flex;
	justify-content: space-evenly;
	border-radius: 10px;
	background: #336cfb;
	color: white;
	font-weight: 700;
	font-size: 14px;
	width: 100%;
	align-items: center;
	height: 26px;
	position: absolute;
	top: -8px;
}
.accordion-item-dea {
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
	height: auto;
	max-height: 9999px;
	margin-bottom: 10px;
}
.position-content-dea {
	position: absolute;
	top: 52px;
	z-index: 1000;
}
