.card__nppes-header .div .span {
	display: block;
	padding-left: 10px;
}
.card__nppes-header .div .title-name {
	font-size: 20px;
	font-weight: 700;
	font-family: var(--ion-font-family2);
	line-height: 20px;
	color: #ffffff;
}
.card__nppes-header .div .subtitle-name {
	font-size: 13px;
	font-weight: 400;
}

.card__nppes-body {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}
.card__nppes-npi {
	color: #fff;
	font-size: 14px;
}
.card__nppes-npi .span {
	font-size: 20px;
	font-weight: 700;
	color: white;
	display: flex;
}
.card__nppes-npi .copy {
	font-size: 18px;
	padding-left: 8px;
}
.card__nppes-body .numbers-ids {
	font-size: 13px;
}
.card__nppes-body .numbers-ids .span {
	display: block;
}
.card__nppes-body .numbers-ids .ids-numbers {
	font-weight: 500;
}

.card__nppes-footer {
	color: #fff;
	font-size: 12px;
	line-height: 15px;
	font-weight: 400;
	opacity: 0.6;
}

.card__nppes-table {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	margin-top: 10px;
}
.card__nppes-table .card__nppes-table-info {
	width: 50%;
}
.card__nppes-table .card__nppes-table-tag .span {
	background: #00a585;
	border-radius: 15px;
	padding: 1px 8px;
	margin-top: 4px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-size: 8px;
	letter-spacing: 1px;
}
.card__nppes-table .card__nppes-table-image {
	width: 27.5%;
	text-align: center;
}
.card__nppes-table .card__nppes-table-image .img {
	float: right;
}
.card__nppes-status ion-text {
	color: #fff;
	padding: 2px;
}
.card__nppes {
	background: linear-gradient(157.43deg, #0c398c 7.9%, #071342 78.54%);
	padding-right: 10px;
	padding-top: 10px;
	padding-left: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}

.front,
.npBack {
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 0;
}

.npBack {
	background: linear-gradient(157.43deg, #0c398c 7.9%, #071342 78.54%);
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 92vw;
	max-width: 400px;
	height: 455px;
	border-radius: 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.noRotate {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}
.card__nppes-status .datetime {
	color: white;
}
.card__nppes-status .dateText {
	font-size: 14px;
	line-height: 16px;
	align-items: baseline;
	margin-bottom: 10px;
}
.white-flip {
	color: white;
	font-weight: 500;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 5px;
	font-size: small;
}
.white-flip ion-icon {
	padding-right: 3px;
}
.img-nppes {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.margin-bottom-issued-nppes {
	margin-top: 10px;
}
.npi-title-width {
	width: 87%;
}
.flex-column-nppes {
	display: flex;
	flex-direction: column;
}
