/*HISTORY DETAIL CONTENT*/

.historyContent .historyContentTitle {
  font-size: 20px;
}

/*HISTORY DETAIL CARD*/

.historyCard {
  padding: 30px 22px;
}

.historyCard .historyDetailRow:last-of-type {
  margin-bottom: 0px;
}

.historyCard .historyDetailRow:last-of-type .timeline {
  display: none;
}


/*HISTORY DETAIL ROW*/

.historyDetailRow {
  margin-bottom: 30px;
}

.historyDetailRow .topLine{
  margin-bottom: 6px;
}

.historyDetailRow .date {
  color: var(--ion-color-nuco);
  font-size:10px;
  font-weight: 500;
  margin-left: 6px;
}

.historyDetailRow .topic {
  color: #004734;  
  font-size: 14px;
  font-weight: 700;
  margin-right: 3px;
}

.historyDetailRow .description {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #52575C;
  margin-top: 10px;
}

.historyDetailRow .descriptionRow {
  width: 100%;
}

.historyDetailRow .status {
  color: var(--ion-color-nuco);
  font-size: 14px;
  font-weight: 700;
}

.historyDetailRow .icon {
  font-size: 40px;
  position: relative;
  z-index: 2;
}

.historyDetailRow .timeline {
  background-color: #E8E8E8;
  width: 5px;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 23px;
  top: 40px;
}

.historyDetailRow {
  z-index: 2;
  position: relative;
}