.loginPage {
	align-items: center;
	background: linear-gradient(rgba(0, 0, 0, 0.727), rgba(0, 0, 0, 0.7));
}
.padding-sign-up {
	padding: 65px 0;
}
.subtitle-sign-up {
	color: rgba(186, 252, 94, 1);
}
.subtitle-sign-up-npi {
	color: #fac032;
}
.login-label__terms {
	font-size: 13px !important;
	padding-left: 10px;
	color: var(--ion-color-medium) !important;
}

.logo-nuco {
	width: 190px;
	height: 60px;
	margin: 60px auto;
}
.title-forgot-password {
	font-weight: 700;
	font-size: 14px;
	color: #fac032;
	text-decoration: none;
}
.create-account-link {
	font-weight: 700;
	font-size: 14px;
	color: #fff;
	text-decoration: none;
}
ion-content.background-sign-in {
	--background: linear-gradient(rgba(0, 0, 0, 0.727), rgba(0, 0, 0, 0.7)),
		url(../../img/splash-screen.png) 0 0/100% 100% no-repeat;
	max-width: 550px;
}
.color-social-btn-red {
	--background: #c5331e;
	--border-radius: 49px;
	width: 100px;
}
.color-social-btn-black {
	--background: #030303;
	--border-radius: 49px;
	width: 100px;
}
.color-social-btn-gray {
	--background: #2f2f2f;
	--border-radius: 49px;
	width: 100px;
}
.label-authorize-caqh {
	padding: 10px 20px;
	width: 100%;
	font-size: 13px;
	color: #52575c;
}
.text-after-title {
	font-size: 16px;
	color: #52575c;
	text-align: center;
	display: flex;
}
.text-bottom-white {
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
}
.btn-color-nuco {
	--background: var(--ion-color-nuco);
}
ion-button.splash-btn {
	height: 40px;
	min-width: 117px;
}
.title-sign-in {
	font-family: var(--ion-font-family2);
	color: #fff;
	font-size: 30px;
	font-weight: 700;
}
ion-row.add-padding {
	padding-top: 50px;
}
.building-wallet-modal {
	width: 80vw;
	height: max-content;
	min-height: 200px;
	border-radius: 10px;
}
.bw-datetime {
	padding: 0;
	font-weight: 500;
}
.bw-modal-title {
	width: 100%;
	padding-bottom: 1rem;
}
.sign-in-width {
	width: 100%;
}
.title-welcome-in {
	font-family: var(--ion-font-family2);
	color: #fff;
	font-size: 30px;
	font-weight: 700;
	display: flex;
	text-align: center;
}
.subtitle-welcome-up {
	color: rgba(186, 252, 94, 1);
	text-align: center;
	display: flex;
	justify-content: center;
}
.ion-toolbar-login {
	--background: none !important;
}
.title-img-header {
	margin-top: 30px;
}
