body {
	font-family: var(--ion-font-family); /* rubik */
	font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--ion-font-family2); /* poppins */
	color: var(--ion-text-color);
}
h1 {
	font-size: 50px;
}
h2 {
	font-weight: 700;
	font-size: 40px;
}
ion-title {
	font-size: 24px;
	font-family: var(--ion-font-family2);
}
ion-item.input-inside {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
}
ion-button {
	--border-radius: 10px;
	height: 48px;
	text-transform: capitalize;
}
ion-toolbar {
	--background: #f7f8fb;
}

.auto-center {
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.logo-img {
	width: 102px;
	height: 27px;
	margin: 0 auto;
}
.no-background {
	--background: none;
}
.color-white {
	color: #fff;
}
.color-light-green {
	color: #bafc5e;
}
.color-light-gray {
	color: #a0a4a8;
}
ion-card.regular-card {
	border-radius: 40px;
	background: #fff;
}

ion-card.regular-card ion-card-header {
	margin-top: 30px;
}
.bg-warning {
	background: var(--ion-color-warning);
}
.bg-success {
	background: var(--ion-color-success);
}
.full-row-cols {
	display: flex;
	width: 100%;
	flex-direction: column;
}
.upload-media-container img {
	max-height: 100%;
}
.upload-media-profile-avatar img {
	max-width: 100%;
}
.max-550 {
	max-width: 550px;
	padding-top: 3vh !important;
}
.backArrowProfile {
	position: absolute;
	left: 0;
	top: 15px;
	font-size: small;
}
.no-padding {
	padding: 0 !important;
}
.grecaptcha-badge {
	transform: scale(0.8) !important;
}
