ion-progress-bar.progress-bar {
	height: 8px;
	border-radius: 10px;
	--buffer-background: #8ef8fa;
	--progress-background: #169496;
}
.container-progress-questions {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.title-count-tasks-questions {
	font-size: 14px;
	margin-bottom: 10px;
}
.content-questions {
	display: flex;
	margin-top: 20px;
	padding-top: 30px;
	font-size: 16px;
}
.center-text-questions {
	text-align: center;
}
.main-content-questions {
	border-radius: 20px;
	background: linear-gradient(0.11deg, #8fd3f4 37.09%, #f6f8fb 111.45%);
	box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
}
.round-buttons-questions-no {
	height: 60px;
	width: 60px;
	display: block;
	border-radius: 50%;
	border: 5px solid #cc202a;
	font-weight: 700;
	font-size: 18px;
	color: #cc202a;
	background: white;
	text-align: center;
	box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 40px;
}
.answer-no-questions {
	background: #cc202a;
	color: grey;
}
.answer-yes-questions {
	background: #039f67 !important;
	color: grey !important;
}
.round-buttons-questions-yes {
	height: 60px;
	width: 60px;
	display: block;
	border-radius: 50%;
	border: 5px solid #039f67;
	font-weight: 700;
	font-size: 18px;
	color: #039f67;
	background: white;
	text-align: center;
	box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.btn-container-questions {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
.width-questionnaire {
	width: 100%;
}
