.BubbleList {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.BubbleList .right {
	align-self: flex-end;
	align-items: flex-end;
}

.BubbleList .left {
	align-self: flex-start;
	align-items: flex-start;
}

.BubbleList .chatBubbleConversation {
	padding: 10px 26px;
	display: flex;
	flex-direction: column;
}
