.header-cred-menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
}
.menu-class-cred {
	--width: 500px;
	overflow-y: auto;
}
.center-data-info-cards {
	display: flex;
	justify-content: center;
}
.option-menu {
	font-size: 20px;
	margin-bottom: 30px;
	padding: 10px;
}
.icon-size-menu {
	width: 25px;
	height: 25px;
	padding-right: 14px;
}
.div-menu-left {
	min-width: 250px;
	max-width: 300px;
	background: white;
	padding: 16px;
	position: absolute;
	left: 0;
	height: 100%;
}
.selected-tab {
	color: #336cfb;
}
.selected-tab ion-icon {
	color: #336cfb;
}
.card-element {
	height: 150px;
	width: 300px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 700;
	border-radius: 10px;
	margin: 10px;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
.container-wallet-cards {
	display: flex;
	flex-wrap: wrap;
	margin: 10px;
}
.drv-license-card {
	background: linear-gradient(158.83deg, #ff6760 1.3%, #e74f48 100%);
	color: white;
}
.nppes-card {
	background: linear-gradient(157.43deg, #0c398c 7.9%, #071342 78.54%);
	color: white;
}
.passport-card {
	background: linear-gradient(158.83deg, #6360ff 1.3%, #2402f2 100%);
	color: white;
}
.dea-card {
	background: linear-gradient(0.36deg, #ebcc98 0.46%, #fae7c4 99.84%);
	color: black;
}
.abms-card {
	background: linear-gradient(158.83deg, #ffeef3 1.3%, #ffa8c7 100%);
	color: black;
}
.board-actions-card {
	background: linear-gradient(158.83deg, #ffbe00 1.3%, #ffbe00 100%);
	color: black;
}
.medical-license-card {
	background: linear-gradient(rgb(182 218 245 / 73%), rgb(255 255 255 / 70%));
	color: #169496;
}
.medicare-card {
	background: linear-gradient(160.52deg, #336cfb 12.11%, #092e86 112.76%);
	color: white;
}
.medicaid-card {
	background: linear-gradient(160.52deg, #2a6465 12.11%, #317272 112.76%);
	color: white;
}
.sam-exclusion-card {
	background-image: linear-gradient(158.83deg, #ffffff 1.3%, #ffffff 100%);
	color: black;
}
.leie-card {
	background: linear-gradient(158.83deg, #2f4b79 1.3%, #091936 100%);
	transition: transform 0.6s ease-out;
	color: #75b4f1;
}
.payors-card {
	background: #ffffff;
	color: #52575c;
}
.passwords-card {
	background: linear-gradient(145.35deg, #5bfab7 12.4%, #039f67 94.48%);
	color: white;
}
.content-menu-wallet {
	display: flex;
	overflow: auto;

	flex-direction: column;
}
.content-title-wallet {
	font-size: 30px;
	margin: 30px;
	font-weight: 700;
}
.container-tasks-quantity {
	flex-grow: 0;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	display: inline-flex;
	background: #ff7e77;
	padding: 0;
	position: relative;
	top: -10px;
	right: 29px;
}
.container-tasks-quantity span {
	font-family: var(--ion-font-family3);
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
