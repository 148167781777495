.content-pass-card {
	background: white;
	display: flex;
	border-radius: 10px;
	margin: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 20px;
}
.label-pass-title {
	font-weight: 500;
	display: flex;
	align-items: center;
	color: #169496;
	width: 100%;
	font-size: 20px;
}
.card-height-sam {
	min-height: 50px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.content-pass-cards {
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.content-pass-cards-collapse {
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 10px;
}
.edit-passwords {
	width: 15px;
	height: 15px;
}
.width-half {
	width: 80%;
}
.width-complete {
	width: 90%;
}
.close-width {
	width: 5%;
}
.edit-width {
	width: 10%;
}
