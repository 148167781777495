.upload-media-container-dea {
	width: 50%;
	border: 2px dashed #dbdde0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	width: 120px;
	height: 80px;
	margin: 5px;
}
.upload-media-container-dea .upload-area-dea {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.upload-media-container-dea .upload-area-dea img {
	margin: 10px;
	width: 54px;
}

.upload-media-container-dea .upload-area-dea ion-label {
	font-size: 13px;
	color: #52575c;
}

.upload-media-container-dea .buttons-area {
	padding: 20px;
}
.upload__main-title-dea {
	font-weight: 700;
	font-size: 14px;
}
.upload-content-info-dea {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.upload-content-info-dea h4 {
	font-size: 14px;
	font-weight: 700;
	color: #52575c;
	margin-bottom: 5px;
}
.upload-content-info-dea ion-label {
	font-size: 13px;
	font-weight: 400;
	color: #a0a4a8;
}
.upload-content-info-dea p {
	text-align: center;
	font-size: 13px;
	line-height: 16px;
	color: #52575c;
}
.row-pics-dea {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background: white;
	border-radius: 20px;
	padding: 10px;
	margin-top: 20px;
}
.upload-main-container-dea {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
}
.uploadFileRow-dea {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-images-dea-upload {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
}
.flex-col-dea {
	display: flex;
	flex-direction: column;
}
.cancel-btn-dea {
	color: black;
	font-size: 14px;
	font-weight: 700;
	padding: 20px;
}
.upload-media-container-no-border-dea {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid #336cfb;
	width: 100%;
	border-radius: 5px;
	padding: 10px;
}
.row-pics-dea-no-bg {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 10px;
	margin-top: 20px;
}
.img-width-dea {
	border-radius: 5px;
	height: 100px;
	width: 180px;
}
.flex-row-dea {
	display: flex;
	flex-direction: row;
}
.flex-col-md-dea {
	display: flex;
	justify-content: center;
}
.edit-dea-text {
	position: absolute;
	justify-content: center;
	align-items: center;
	bottom: -6px;
	right: -2px;
	background: #336cfb;
	border-radius: 10px;
	padding-left: 7px;
	padding-right: 7px;
	font-size: 12px;
	font-weight: 500;
	color: white;
}
.container-edit-dea-text {
	position: relative;
	justify-content: flex-end;
}
.upload-media-container-no-border-pdf-dea {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid #336cfb;
	width: 100%;
	border-radius: 5px;
	padding: 10px;
}
