.ChatToolbar {
	background-color: transparent;
	width: 100%;
	display: flex;
	align-items: center;
}

.ChatToolbar .avatarContainer {
	align-self: center;
	margin-right: 15px;
}

.ChatToolbar .avatarToolbar {
	width: 30px;
	height: 30px;
}

.ChatToolbar .avatarToolbar img {
	width: 30px;
	height: 30px;
}

.ChatToolbar .name {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #000000;
}
