
  .accordion-title-payer-actions {
    font-family: var(--ion-font-family3);
    font-weight: 500;
    font-size:10.1977px;
    line-height: 12px;
    color: #03B575;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

.card-row-payer{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.card-row-payer ion-col.center-text-col{
  text-align: center;
}
.card-row-payer ion-col.center-icon-col ion-img{
  margin:0 auto;
}
.payer-logo{
  position: absolute;
width: 74.44px;
height: 41.81px;
left: 30.28px;
top: 105.48px;
}
.payer-text-estimated-days{
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #FF6760;
  border-radius: 5.09883px;
}
  .payer-text-estimated-days ion-text.days-text{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 10.1977px;
  
  }
  .efective-date-payer{
    color: #00A585!important;
  }
  .margin-payer{
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .name-history{
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
  } .date-history{
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    color: #4ba6e2!important;
  }
  .row-title-history{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-width{
    width: 100%;
  }
  .error-date{
    font-size: 16px;
    color: red;
    font-weight: 500;
  }