.grid-what-we-need{
    color:var(--ion-color-dark);
    --ion-grid-width:100%;
}
.ellipse-progress-need{
    min-width: 80px;
    border-radius: 20px;
    height: 25px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    vertical-align: middle;
    line-height: 25px;
    font-family: var(--ion-font-family3);
    color: #fff;
}
.col-need-one{
   /* width: calc(100% - 172px);  */
   width: 45%;
   font-size: 14px;
}
.col-need-two{
    /* width: 82px;
    padding: 0 10px; */
    width: 30%;
    min-width: 84px;
}
.col-need-three{
    /* width: 50px;
    padding: 0 10px; */
    width: 25%;
    min-width: 50px;
    text-align: right;
}
.col-need-three ion-router-link{
    padding-left:10px;
}
.tasks-content-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    color: #52575C;
    padding-bottom: 10px;
}
.grey-color{
    background: gray;
}

.what-we-need-width{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.by-when-style{
font-size: 14px;
line-height: 20px;
text-align: left;
letter-spacing: 0.1px;
color: #000000;
}
.color-red{
    color: #EE312F;
    font-weight: bold;
}
.div-task-title{
    display: flex;
    align-items: self-end;
}
.check-width{
    width: 15%;
}
.text-width{
    width: 85%;
}
