.signature-icon {
	flex-grow: 0;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: inline-flex;
	padding: 0;
	margin-left: 15px;
}
.category-title-signature {
	font-size: 20px;
	text-align: left;
	color: black;
	margin-left: 0.6rem;
	font-weight: 700;
}
.description-title-signature {
	font-weight: 400;
	font-size: 16px;
	margin-top: 20px;
	padding: 10px;
}
.view-more-desc-signature {
	font-weight: 400;
	font-size: 16px;
	margin-top: 15px;
	padding: 10px;
	color: #336cfb;
	text-decoration: underline;
}
.cancel-signature {
	font-weight: 700;
	font-size: 14px;
	text-decoration: none;
}
.row-btn-signature {
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn-use-signature {
	width: 60%;
}
.signature-canvas {
	border: 1px solid #d9d9d9;
	width: 100%;
	height: 200px;
	border-radius: 10px;
	margin: 0 auto;
}
.signature-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.clear-button {
	position: absolute;
	top: 0px;
	right: 0px;
	margin: 0px;
	background: #a7a7a7;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 5px;
}
.icon-size {
	width: 25px;
	height: 25px;
}
.signature-image {
	display: block;
	margin: 0px auto;
	width: 100%;
	height: 200px;
}
@media screen and (max-width: 320px) {
	ion-select.payer-select::part(text) {
		color: #666666;
		font-weight: 500;
		font-size: 15px !important;
		line-height: 12px;
		max-width: 60px;
	}
	.text-style-payer {
		color: #52575c;
		font-size: 14px !important;
		font-weight: 500;
		padding-right: 32px;
	}
	.status-pending {
		font-weight: 500;
		font-size: 14px !important;
		color: #fac032;
		padding-top: 5px;
	}
	.status-active {
		font-weight: 500;
		font-size: 14px !important;
		color: #16d090;
	}
	.status-inactive {
		font-weight: 500;
		font-size: 14px !important;
		color: #e74f48;
	}
}
@media screen and (max-width: 375px) and (min-width: 321px) {
	.text-payer {
		font-weight: 600;
		font-size: 14px !important;
		color: #25282b;
		padding-top: 5px;
	}
	.text-payer-provider {
		font-weight: 400;
		font-size: 14px !important;
		color: #25282b;
	}
	.status-pending {
		font-weight: 500;
		font-size: 14px !important;
		color: #fac032;
		padding-top: 5px;
	}
	.status-active {
		font-weight: 500;
		font-size: 14px !important;
		color: #16d090;
	}
	.status-inactive {
		font-weight: 500;
		font-size: 14px !important;
		color: #e74f48;
	}
}
.payer-datetime {
	padding: 0 !important;
	display: block;
	font-size: 12px;
	color: #25282b;
}
.card {
	transform-style: preserve-3d;
	transform-origin: center;
	transition: transform 1s;
}
.card__face {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 455px;
	backface-visibility: hidden;
}
.text-payer {
	font-weight: 400;
	font-size: 14px;
	color: #a0a4a8;
	line-height: 20px;
	margin-top: 10px;
}
.text-payer-provider {
	font-weight: 400;
	font-size: 14px;
	color: #25282b;
	line-height: 20px;
}
.row-footer-payer {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	color: #000000;
	font-weight: bold;
	font-size: 14px;
}
.noZoom {
	overflow: hidden;
	max-height: 300px;
}
.zoom {
	overflow: scroll;
}
.footer_payer {
	display: flex;
	justify-content: center;
	align-items: center;
}
.background_payer {
	background: #ffffff;
	padding-top: 10px;
	-webkit-transition: -webkit-transform 0.6s;
	-moz-transition: transform 0.6s ease-out;
	-o-transition: transform 0.6s ease-out;
	transition: transform 0.6s ease-out;
	cursor: pointer;
	border-radius: 20px;
	min-height: 455px;
	width: 92vw;
	max-width: 400px;
}
.article-header_payer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	color: #52575c;
	font-size: 20px;
	font-weight: 400;
	padding-left: 10px;
}
.card__face_zoom__payer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	backface-visibility: hidden;
}

/* ready */
ion-select.payer-select::part(text) {
	color: #666666;
	font-weight: 500;
	font-size: 16px;
	line-height: 12px;
}
ion-select.payer-select::part(icon) {
	margin-left: 1em;
	opacity: 1;
}
ion-item.item-payer::part(native) {
	background-color: #ffffff;
}
.dropdown-row {
	display: flex;
	justify-content: right;
	width: 100%;
	align-items: self-end;
}

.row-logo-payer {
	height: 40px;
}
.flex-container-row-select {
	display: flex;
	align-items: center;
}
.flex-item-left {
	flex: 15%;
	display: flex;
	justify-content: center;
}
.flex-item-right {
	flex: 90%;
	flex-direction: row;
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
	padding-right: 10px;
	align-items: center;
}
.flex-item-accordeon {
	flex: 90%;
	flex-direction: column;
	display: flex;
}
.row-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: 10%;
}
.title-text-payer {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
	margin-top: 5px;
	padding-bottom: 10px;
}
.status-pending {
	font-weight: 500;
	font-size: 14px;
	color: #fac032;
	padding-top: 5px;
}
.status-pending-color {
	color: #fac032;
}
.status-active {
	font-weight: 500;
	font-size: 14px;
	color: #16d090;
}
.status-active-color {
	color: #16d090;
}
.status-inactive {
	font-weight: 500;
	font-size: 14px;
	color: #e74f48;
}
.status-inactive-color {
	color: #e74f48;
}
.mlNoRotatePayer {
	padding: 0;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
}
.flip {
	color: black;
	font-weight: 500;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 5px;
	font-size: 14px;
}
.flip ion-icon {
	padding-right: 3px;
}
.mlFront,
.pyBack {
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 0;
}
.pyBack {
	background: #ffff;
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	padding-left: 10px;
	padding-top: 10px;
	width: 100%;
	height: 390px;
	border-radius: 20px;
	color: black;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
ion-input.input-search-payer {
	--color: #52575c;
	--placeholder-color: #52575c;
	--placeholder-font-weight: 400;
	background-color: #ebf0ff;
	width: 94%;
	border-radius: 20px;
	--padding-start: 15px;
	margin-left: 10px;
	margin-top: 10px;
	font-weight: 400;
	font-size: 14px;
	margin-right: 10px;
}
.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.close-card-payer {
	width: 100%;
	justify-content: center;
	padding: 10px;
	display: flex;
	font-size: 14px;
}
.wrapper {
	min-height: calc(100% - 150px);
	position: relative;
}
.wrapper-zoom {
	padding-bottom: 60px;
	min-height: calc(100% - 60px);
	position: relative;
}
.footer-payer-container {
	color: white;
	background-color: #ffffff;
	width: 100%;
	height: 60px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.container {
	padding: 12px;
}
.payers-background {
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;
	margin-top: 20px;
}
.row-header-payer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.row-status-payer {
	display: flex;
	align-content: center;
	padding-left: 10px;
	justify-content: space-between;
}
/* ready */
.text-style-payer {
	color: #52575c;
	font-size: 16px;
	font-weight: 500;
	padding-right: 32px;
}
.payor-find-icon {
	position: absolute;
	top: 60px;
	z-index: 2;
	right: 30px;
}
.payer-width {
	width: 100%;
}
.item-border-select {
	border: 1px solid #e8e8e8;
	border-radius: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 20px;
	width: 80%;
}
.flex-col-payer {
	display: flex;
	flex-direction: column;
}
.find-payer {
	position: absolute;
	right: 30px;
	top: 75px;
}
.wrapper-zoom-payer {
	padding-bottom: 120px;
	min-height: calc(100% - 120px);
	position: relative;
}
.icon-about-payer {
	width: 24px;
	height: 24px;
	position: absolute;
	right: 25px;
}
.accordeon-payer-btn-section {
	display: flex;
	justify-content: center;
}
.row-btn-section-payer {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
	margin-right: 10px;
	font-weight: 500;
	line-height: 20px;
}
.icon-share-payor {
	width: 50px;
	height: 50px;
	background: #336cfb;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.icon-payor-share-btn {
	width: 15px;
	height: 17px;
}
.payer-color-text-btn {
	color: #52575c;
}
.icon-share-payor-btn {
	width: 50px;
	height: 50px;
	background: #336cfb;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.default-signature {
	height: 200px;
	background: #d9d9d9;
	border-radius: 10px;
}
.clear-button-default {
	display: flex;
	justify-content: end;
	border-radius: 10px;
}
.content-clear-btn-default {
	margin: 0px;
	background: #a7a7a7;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 5px;
}
.default-btn-center {
	display: flex;
	justify-content: center;
}
.sign-in-width-default {
	width: 60%;
}
