.card-share {
    transform-style: preserve-3d;
    transform-origin: center;
    transition: transform 1s;
}
.card-share-actions {
 background: linear-gradient(#2A6465 0%, #317272 100%);
background-position: bottom;
background-repeat: repeat-x;
-webkit-transition: -webkit-transform 0.6s;
-moz-transition: transform 0.6s ease-out;
-o-transition: transform 0.6s ease-out;
transition: transform 0.6s ease-out;
cursor: pointer;
border-radius: 20px;
min-height: 455px;
width: 92vw;
max-width: 400px;
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 15px;
}
.wrapper-zoom-share {
    padding-bottom: 120px;
    min-height: calc(100% - 120px);
    position: relative;
  }
  .header-share-actions{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .share-title{
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .share-sub-title{
    font-size: 16px;
    color: #FFFFFF;
  }
  ion-select.share-select::part(text) {
    color: #25282B;
    font-size: 14px;
    line-height: 12px;
    width: 100%;
  }
  ion-select.share-select::part(icon) {
    margin-left: 1em;
    opacity: 1;
    margin-right: 7px;
  }
  ion-select.share-select.alert-wrapper {
    background: #e5e5e5;
  }
  ion-item.item-share::part(native) {
    background-color: #FFFFFF;
  }
  .combo-style{
    display: flex;
    flex-direction: column;
    padding-top: 16px;
  }
  .share-select{
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-top: 10px;
  }
  .on-off-label-share {
    color: #FFFFFF!important;
    font-weight: bold;
    font-size: 14px;
}
.toggle-share{
    --background-checked: #336CFB;
    --handle-background-checked: #FFFFFF;
}
.btns-share-active {
  height: 35px;
  font-weight: 700;
  font-size: 14px;
  --background: #00A585;
}

.btns-share-inactive {
  height: 35px;
  font-weight: 700;
  font-size: 14px;
  --background: #004734;
}
.email-share{
  background: #FFFFFF;
  font-size: 14px;
  border-radius: 10px;
  margin-top: 10px;
}
.app-opened {
  box-sizing: border-box;
  overflow: scroll;
  background: transparent;
  z-index: 100;
  top: 10px;
  animation: zoom-in 0.5s ease-out;
  visibility: visible !important;
}
.row-scan-share {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
ion-select.share-select::part(text) {
  color: #666666;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  max-width: 110px;
}
.share-select{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.space-between-share{
  justify-content: space-between;
}
/* for the modal */
.title-modal{
  display: flex;
    color: #A0A4A8;
    font-weight: 400;
    font-size: 17px;
    justify-content: center;
    border-bottom: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
    height: 70px;
    align-items: center;
}
.modal-share-what{
  --width: 300px;
  --max-height: 350px;
  --border-radius: 8px;
}
.modal-flex-column{
  display: flex;
  flex-direction: column;
}
.title-modal-option{
  color: #25282B;
  font-weight: 400;
  font-size: 14px;
}
.sub-title-modal-option{
  color: #52575C;
  font-weight: 400;
  font-size: 13px;
}
.row-btn-modal{
  display: flex;
  justify-content: center;
  border-top: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}
.btn-modal-color{
  --background: transparent;
    --color: #3880ff;
    font-size: 17px;
}
.modal-content-center{
  justify-content: center;
}
.border-right-modal{
  border-right: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}
.row-select-share{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    background: white;
    height: 38px;
    align-items: center;
    padding: 10px;
    color: #A0A4A8;
}
.modal-check-color{
  --checkmark-color: white;
  --background-checked: #16D090;
  --border-color-checked: #16D090;
}
.modal-wrapper .ion-page{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    flex-direction: column;
    contain: layout size style;
    overflow: auto;
    z-index: 0;
    justify-content: space-evenly;
    padding: 10px;
}
.color-text-share{
  color: #25282B;
  font-size: 14px;
  font-weight: 400;
}
.icon-date-padding{
  padding-left: 10px;
  padding-right: 10px;
}
.date-share{
  border: 1px solid var(--ion-color-medium);
    border-radius: var(--radius);
    background: white;
    height: 40px;
}
.item-date{
  width: 100%;
    border-radius: 10px;
}
.item-date-right-icon{
  position: absolute;
  right: 25px;
  top: 250px;
  z-index: 1;
}
.btns-share-cancel {
  height: 35px;
  font-weight: 700;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
}
.row-save-form{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  margin-top: 40px;
}
@media screen and (max-width: 379px) {
  .space-between-share{
    justify-content: space-between;
    flex-direction: column;
  }
}
.email-share .native-input{
  padding-left: 10px;
}