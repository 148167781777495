.bubble .view {
	background-color: white;
	box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 5px 24px 4px 17px;
	letter-spacing: 0.1px;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
}

.chatBubbleConversation .view {
	max-width: 250px;
}

.bubble .datetime {
	color: #a0a4a8;
	letter-spacing: 0.1px;
	font-style: italic;
	font-weight: normal;
	font-size: 10px;
	line-height: 16px;
}

.bubble.left .view {
	color: #336cfb;
	border: 1px solid #336cfb;
}

.bubble.right .view {
	color: #00a585;
	border: 1px solid #00a585;
}
