.no-info-card-bg {
	background: transparent;
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	padding: 10px;
}
.waiting-clock-no-info {
	width: 70px;
	height: 70px;
}
.text-color-white {
	color: white;
}
.text-color-black {
	color: black;
}
.center-data-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 16px;
	min-height: 300px;
}
.desc-no-info {
	font-weight: 700;
	font-size: 20px;
}
