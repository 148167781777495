ion-content .main {
	display: flex !important;
	justify-content: center;
	align-items: flex-end;
}
.main-content {
	overflow: scroll;
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
}
.top-page-wallet-web-version {
	top: 5vh !important;
	bottom: 75px !important;
	background: #f7f8fb;
	align-items: center;
	margin-left: 252px;
}
@media only screen and (min-width: 1224px) {
	.top-page {
		top: 8vh !important;
	}
}
.main-div {
	display: flex;
	justify-content: center;
	height: 100%;
}

.headerNone {
	display: none;
}
.start-page-wallet-web {
	margin-left: 249px;
	height: 100%s;
}
