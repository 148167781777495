.accordion-wrapper + * {
	margin-top: 0em;
}
.accordion-item {
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
	height: auto;
	max-height: 9999px;
}
.accordion-item.collapsed {
	max-height: 0;
	transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-row-abms {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.uppercase-title {
	text-transform: uppercase;
}
.accordion-title-abms:hover,
.accordion-title-abms.open {
	color: black;
}
.accordion-content-abms {
	padding-bottom: 10px;
}
.card-row-abms {
	display: flex;
	flex-direction: column;
	padding: 10px;
}
.border-top-row-abms {
	border-top: 1px solid #3c3c3c;
}
.border-bottom-row-abms {
	border-bottom: 1px solid #3c3c3c;
}
.card-row-abms ion-col.center-text-col {
	text-align: center;
}
.card-row-abms ion-col.center-icon-col ion-img {
	margin: 0 auto;
}

ion-img.row-icon {
	width: 16px;
	height: 10px;
}

.row-text_title {
	font-size: 20px;
	font-weight: 500;
	color: #000;
	text-transform: capitalize;
}

.row-text_active_abms {
	color: #00744a;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 16px;
	font-weight: 700;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.card-row-abms ion-text.pre-title {
	text-transform: capitalize;
	font-family: var(--ion-font-family3);
	font-size: 12px;
	color: #3c3c3c;
	font-weight: 700;
	line-height: 16px;
}
.card-row-abms ion-text.title {
	text-transform: capitalize;
	font-family: var(--ion-font-family3);
	font-size: 18px;
	color: #000;
	font-weight: 900;
	padding-top: 5px;
}
.card-row-abms ion-text.sub-title {
	text-transform: capitalize;
	font-family: var(--ion-font-family3);
	font-size: 11px;
	color: #00744a;
	font-weight: 500;
	padding-top: 5px;
	padding-bottom: 5px;
}
.flex-row-space-between {
	flex-direction: row;
	justify-content: space-between;
}
.status-abms-accordeon {
	font-weight: 500;
	font-size: 14px;
	line-height: 12px;
	color: #000000;
}
.card-position-abms {
	position: absolute;
	z-index: 1000;
}
