.building-wallet-ready-main-text {
	font-size: large;
	color: white;
	background: #fff;
	border-radius: 10px;
	overflow-y: scroll;
	margin-top: 55px;
	padding: 10px;
}
.building-wallet-ready {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	height: 100%;
	background: rgba(0, 71, 52, 0.75);
}
.building-wallet-ready-background {
	background: rgba(0, 0, 0, 0.5);
}
.bw-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}
.bw-h1 {
	font-size: 40px;
	font-weight: 700;
	align-self: flex-start;
	padding-left: 5vw;
}
.bw-subTitle {
	font-size: 18px;
	font-weight: 400;
	align-self: flex-start;
	padding-left: 5vw;
}
.padding-top-20px {
	padding-top: 20px;
}
.underline {
	text-decoration: underline;
}
.bw-progress-content {
	width: 100%;
	flex-direction: column;
	justify-content: space-around;
	min-height: 20vh;
}
.wallet-img {
	height: max-content;
	width: max-content;
}
.bw-bold {
	font-weight: 800;
	font-size: 20px;
}
.bw-text {
	font-size: 15px;
	text-align: center;
}
.color-green {
	color: #00a585;
}

.progress {
	background: rgba(255, 255, 255, 0.1);
	justify-content: flex-start;
	border-radius: 25px;
	align-items: center;
	position: relative;
	padding: 0 5px;
	display: flex;
	height: 10px;
	width: 80vw;
}

.progress-value {
	animation: load 3s normal linear infinite;
	box-shadow: 0 10px 40px -10px #fff;
	border-radius: 25px;
	background: #fff;
	height: 10px;
	width: 0;
}

@keyframes load {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
.bw-progress-row {
	justify-content: center;
}
.bw-progress-icon {
	font-size: 30px;
}
.bw-progress-bar {
	width: 70%;
}
.bw-progress-row-text {
	flex-wrap: nowrap;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	justify-content: space-around;
}
.bw-img-row {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
