.accordion-wrapper + * {
  margin-top: 0em;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
  font-family: var(--ion-font-family3);
  font-weight: 900;
  font-size: 12px;
  cursor: pointer;
  line-height: 14px;
  color: #25282b;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.uppercase-title {
  text-transform: uppercase;
}
.accordion-title:hover,
.accordion-title.open {
  color: black;
}
.accordion-content {
  padding: 0 1.5em 1.5em 1.5em;
}

.accordion-title ion-text {
  max-width: 200px;
}
.card-row-medicare {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-top: 1px solid #7591D7;
}
.center-text-col-med {
  text-align: center;
  display: flex;
  align-items: center;
}
.card-row ion-col.center-icon-col ion-img {
  margin: 0 auto;
}
ion-img.row-icon {
  width: 16px;
  height: 10px;
}

.row-text_title-medicare {
  font-size: 20px;
  font-weight: 500;
  color: pink;
  text-transform: capitalize;
}

.row-text_active-medicare {
 color: white;
 text-transform: uppercase;
 font-size: 14px;
 font-weight: 500;
 border-radius: 25px;
 padding-right: 10px;
 padding-left: 10px;
 padding-top: 5px;
 padding-bottom: 5px;

}
.row-text_active-color {
  background-color: #16D090;
 }
 .row-text-inactive-color {
  background-color: red;
 }
.highlights {
  border-radius: 25px;
  border: rgba(22, 208, 144, 1);
  padding: 5px;
  text-align: center;
  margin: 4px 2px;
}
.border-top-bottom-medicare {
  
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-top: 1px solid #7591D7;
  border-bottom: 1px solid #7591D7;
}
.row-text-title-medicare-accordion{
  font-size: 20px;
  color: #FFFFFF;
  text-transform: capitalize;
  line-height: 26px;
  font-weight: bold;
}
.row-expand-card{
  display: flex;
  justify-content: space-around;
  align-items: center;
}