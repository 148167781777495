.status-text-style{
  border-radius: 40px;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  padding-right: 5px; 
  text-transform: uppercase;
  padding-bottom: 5px;
  padding-top: 5px;
  justify-content: center;
  }
  .active-bg-color{
    background: #16D090;
  }
  .inactive-bg-color{
    background: #A0A4A8;
  }
  .expired-bg-color{
    background: #FF6760;
  }
  .label-width{
    width: 90px;
  }