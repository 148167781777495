.okButton {
	width: 50px;
}
.uploadTitle {
	text-align: center;
	padding-top: 40px;
	color: black;
}
.uploadSubTitle {
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	padding: 20px;
	color: rgba(37, 40, 43, 1);
	text-transform: none;
}
.upload-onboarding-main-container {
	display: flex;
	flex-direction: column !important;
	width: 100%;
	height: 100%;
}
