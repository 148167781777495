
  .accordion-wrapper + * {
    margin-top: 0em;
  }
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }
  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  .accordion-title-board-actions {
    font-family: var(--ion-font-family3);
    font-weight: 900;
    font-size:12px;
    cursor: pointer;
    line-height: 14px;
    color: #25282B;
    padding: 0.5em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
 
  .accordion-title-board-actions:hover,
  .accordion-title-board-actions.open {
    color: black;
  }

  .accordion-content-board-actions {
    padding-bottom:10px;
  }
 
  .accordion-title-board-actions ion-text{
    max-width:200px;
  }
  .card-row-board-actions{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    z-index: 10;
}
.card-row-board-actions ion-col.center-text-col{
    text-align: center;
}
.card-row-board-actions ion-col.center-icon-col ion-img{
    margin:0 auto;
}

ion-img.row-icon{
  width:16px;
  height:10px
}

.row-text_title{
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.row-text_active{
  color:#00744A;
  text-transform: uppercase;
  font-size: 10.2px;
  font-weight:700;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.card-row-board-actions ion-text.pre-title{
  font-size: 12px;
  color: #25282B;
  font-weight: 300;
  line-height: 16px;
}
.card-row-board-actions ion-text.title{
  text-transform: uppercase;
  font-size: 14px;
  color: #25282B;
  font-weight: 900;
  padding-top: 5px;
  line-height: 16px;
}
.card-row-board-actions ion-text.sub-title{
  font-family: var(--ion-font-family3);
  font-size: 11px;
  color: #00744A;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
}

.number-circle-board-actions {
  flex-grow: 0;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-flex;
  background: #FF6760;
  padding: 0;
}

.number-circle-board-actions span {
  font-family: var(--ion-font-family3);
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  width: 25px;
  display: inline-block;
  margin: auto;
}
.order-date-title-actions-accordeon{
  color: #25282B;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
}
  