.input-radio-style {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
}
.flex-div {
	display: flex;
}
.startDateStyle {
	flex: 100%;
	margin-right: 2px;
}
.endDateStyle {
	flex: 100%;
	margin-left: 2px;
}
.input-radius-tasks {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
	padding: 5px;
}
.flex-order-column {
	display: flex;
	flex-direction: column;
}
.input-font-size-tasks {
	font-size: 20px;
}
.dateInput .date-time-style {
	border: none;
	width: 100%;
	background: none;
}
div div .test input {
	border: none;
	width: 100%;
	background: none;
}
.input-date-border-style {
	border: 1px solid var(--ion-color-medium);
	border-radius: var(--radius);
	padding: 15px;
}
.test {
	width: 100%;
}
.margin-name-history {
	margin-right: 5px;
}
@media only screen and (max-width: 580px) {
	.flex-div {
		display: flex;
		flex-direction: column;
	}
}
.error-date {
	font-size: 16px;
	color: red;
	font-weight: 500;
}
.label-input-style {
	font-weight: 400;
	font-size: 14px;
	color: #a0a4a8;
}
.label-later-missing {
	color: #595858;
	font-size: 14px;
	font-weight: 400;
	text-decoration-line: underline;
}
.toolbar-title {
	text-overflow: unset !important;
	white-space: unset !important;
}
.text-instructions-missing-document {
	display: flex;
	flex-direction: column;
}
.center-missing-data {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.container-title-task {
	display: flex;
	justify-content: center;
	font-size: 24px;
	margin-top: 20px;
}
