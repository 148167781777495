.idRow-uploads {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.steps {
	font-size: large;
	font-weight: bold;
}
.radioGroup {
	display: flex;
	justify-content: left;
	padding: 0 !important;
}
.stepsRow {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 70vh;
}
.onBoardingImg {
	width: 100px;
}
.verifyCard {
	max-height: 70%;
	min-height: 500px;
}
.progressBar {
	position: absolute;
	bottom: 20px;
	margin-left: 20px;
	margin-right: 20px;
	width: 80%;
}
.verifyTitle {
	color: white;
	font-size: 24px;
	font-weight: bold;
	display: block;
	padding: 10px;
	margin-bottom: 20px;
}
.page-identify {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 90%;
}
.card-identify {
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	height: 200px;
	border-radius: 20px;
}
.img-identify {
	height: 200px;
	width: 75%;
}
.text-identify {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}
.button-identify {
	width: 90%;
	margin-bottom: 20px;
}
.backArrowVerify {
	position: absolute;
	left: 0;
	top: 15px;
	font-size: small;
}
.verifiy-center {
	display: flex;
	justify-content: center;
}
.save-row-verify-identity {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
}
.select-state-verify-identity {
	width: 100%;
	--placeholder-color: black;
	--placeholder-opacity: 1 !important;
}
.verify-identity-select-width {
	width: 80%;
}
.input-code-verify-identity {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 80px;
	margin: 10px;
	text-align: center;
}
.errorIdentity {
	color: red;
}
.account-activate-desc {
	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
}
.verifyCard-st4 {
	max-height: 70%;
	top: -50px;
	border-radius: 20px;
}
.row-input-code-verify-st4 {
	margin-top: 40px;
}
/* .img_ssn_security_vi {
	height: 200px;
	position: absolute;
	right: 168px;
	z-index: 2;
}
.img_ssn_security_circle_vi {
	height: 300px;
	top: 97px;
	position: absolute;
	z-index: 1;
	right: 127px;
}
.container-ssn-security-text-vi {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 210px;
	z-index: 3;
	right: 150px;
	color: white;
	font-size: 15px;
}
.font-weigth-vi {
	font-weight: 800;
}
.padding-left-vi {
	padding-left: 20px;
}
.center-container-vi {
	display: flex;
	justify-content: center; 
	align-items: center; 
	position: relative;
	margin-top: 90px;
} */
.main-container-vi {
	margin-top: 50px;
	display: flex;
	justify-content: center; /* Centra horizontalmente el div contenedor */
	align-items: flex-start; /* Alinea el div contenedor en la parte superior */
	/* Asegura que el contenedor tome la altura completa de la ventana */

	position: relative;
}

.center-container-vi {
	display: flex;
	flex-direction: column;
	align-items: center; /* Centra el contenido horizontalmente dentro del div */
	/* Espacio desde la parte superior */
	text-align: center; /* Centra el texto */
	position: relative;
}

.image-container-vi {
	width: 100%;
	position: relative;
	/* Ajusta el tamaño según sea necesario */
	height: 200px; /* Ajusta el tamaño según sea necesario */
	/* Espacio entre las imágenes y el texto */
}

.img-ssn-security-circle {
	position: absolute;
	width: 100%; /* Asegura que la imagen del círculo ocupe todo el contenedor */
	/* Asegura que la imagen del círculo ocupe todo el contenedor */
	top: 45px;
}

.img-ssn-security-lock {
	position: absolute;
	width: 70%; /* Ajusta el tamaño del candado */
	height: 70%; /* Ajusta el tamaño del candado */
	top: -15%; /* Ajusta la posición del candado */
	left: 15%; /* Ajusta la posición del candado */
}

.container-ssn-security-text {
	color: white;
	display: flex;
	flex-direction: column;
	position: relative;
	top: -70px;
	padding: 10px;
}

.text-line {
	font-size: 1.2em;
}

.text-bold {
	font-size: 1.2em;
	font-weight: 800;
}
.save-row-verify-identity-ssn {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 100px;
	left: 0;
	right: 0;
}
.save-row-verify-identity-ssn-mobile {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	bottom: 0px;
	left: 0;
	right: 0;
}
.btn-save-ssn-width {
	width: 80%;
}
