.accordion-wrapper-sam + * {
	margin-top: 0em;
}
.accordion-item-sam {
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
	height: auto;
	max-height: 9999px;
}
.accordion-item-sam.collapsed {
	max-height: 0;
	transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title-sam-actions {
	font-family: var(--ion-font-family3);
	font-weight: 900;
	font-size: 12px;
	cursor: pointer;
	line-height: 14px;
	color: #25282b;
	padding: 0.5em 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.accordion-title-sam-actions:hover,
.accordion-title-sam-actions.open {
	color: black;
}

.accordion-content-sam-actions {
	padding-bottom: 10px;
}

.accordion-title-sam-actions ion-text {
	max-width: 200px;
}
.card-row-sam-actions {
	width: 100%;
	align-items: center;
}
.card-row-sam-actions ion-col.center-text-col {
	text-align: center;
}
.card-row-sam-actions ion-col.center-icon-col ion-img {
	margin: 0 auto;
}

ion-img.row-icon-sam {
	width: 16px;
	height: 10px;
}

.row-text_title-sam {
	font-size: 20px;
	font-weight: 500;
	color: #000;
	text-transform: capitalize;
}

.row-text_active-sam {
	color: #00744a;
	text-transform: uppercase;
	font-size: 10.2px;
	font-weight: 700;
}

.flex-col-sam {
	display: flex;
	flex-direction: column;
}

.card-row-sam-actions ion-text.pre-title-sam {
	font-size: 12px;
	color: #25282b;
	font-weight: 300;
	line-height: 16px;
}
.card-row-sam-actions ion-text.title-sam {
	font-size: 16px;
	color: #25282b;
	font-weight: 700;
}
.card-row-sam-actions ion-text.sub-title-sam {
	font-family: var(--ion-font-family3);
	font-size: 11px;
	color: #00744a;
	font-weight: 500;
	padding-top: 5px;
	padding-bottom: 5px;
}

.number-circle-sam {
	flex-grow: 0;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: inline-flex;
	background: #ff6760;
	padding: 0;
}

.number-circle-sam span {
	font-family: var(--ion-font-family3);
	font-size: 12px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	width: 25px;
	display: inline-block;
	margin: auto;
}
.order-date-title-sam-accordeon {
	color: #25282b;
	font-weight: 300;
	font-size: 12px;
	line-height: 12px;
	position: absolute;
}
.row-content-exc-sam {
	background: #d9d9d9;
	border-radius: 10px;
	padding: 5px;
}
.count-exclusion-sam {
	flex-grow: 0;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: inline-flex;
	padding: 0;
}
.exc-found-color-sam {
	background: #f05851;
}
.exc-possible-color-sam {
	background: #6d55ff;
}
.exc-prev-match-color-sam {
	background: #112e4f;
}
.count-exclusion-circle-sam {
	font-family: var(--ion-font-family3);
	font-size: 14px;
	font-weight: 700;
	height: 100%;
	width: 100%;
	display: flex;
	color: white;
	margin: auto;
	justify-content: center;
	align-items: center;
}
.exclusion-name-sam {
	font-weight: 700;
	font-size: 14px;
	color: #000000;
	margin-left: 10px;
}
.exclusion-data-sam {
	font-size: 14px;
	color: #000000;
	margin-left: 10px;
	padding-top: 5px;
}
.row-content-exc-sam {
	background: #e9e8ff;
	border-radius: 10px;
	padding: 5px;
}
